import { store } from "core/store/configureStore"
import html2canvas from "html2canvas"
import { setSnapshotWarning } from "library/common/actions/modal"
import {
  setServerError,
  setServerErrorMessage,
} from "library/common/actions/user"
import { isAnalysisPage } from "./urls"
import { getActivePatientResult } from "library/common/selectors/patient"
import { formatIteroPatientInfo } from "./patients"

let pageRoot: HTMLElement | null = null

export const setScreenshotPageRoot = (root: HTMLElement | null) =>
  (pageRoot = root)

export const renderScreenshot = async (type: string) => {
  if (!pageRoot) return
  const canvas = await html2canvas(pageRoot, {
    backgroundColor: "#1c2038",
    foreignObjectRendering: false,
    useCORS: true,
  })
  return canvas.toDataURL(type)
}

const iteroSnapshot = async (externalPatient: string, sessionId: string) => {
  // ensure we are on the right page
  if (!isAnalysisPage(window.location)) {
    console.log("wrong page: ", window.location.pathname)
    store.dispatch(setSnapshotWarning({ key: "app.modal_snapshot.wrong_page" }))
    return
  }

  // ensure the current patient is linked to the externalPatient
  const state = store.getState()
  const links = getActivePatientResult(state)?.links
  if (!links?.find((link) => link.externalId === externalPatient)) {
    const patientInfo = formatIteroPatientInfo(
      state.patients.externalPatientContext
    )
    store.dispatch(
      setSnapshotWarning({
        key: "app.modal_snapshot.wrong_link",
        options: { patientInfo },
      })
    )
    return
  }

  // hide sub header with patient information, if it exists
  const subHeader = document.querySelector('div[data-testid="sub-header"]')
  const hasSubheader = subHeader instanceof HTMLDivElement
  hasSubheader && (subHeader.style.visibility = "hidden")
  const screenshotUrl = await renderScreenshot("image/jpeg").catch((error) => {
    console.error("error generating screenshot", error)
    store.dispatch(setServerError("capturing screenshot"))
    store.dispatch(setServerErrorMessage(`${error}`))
  })
  // bring it back
  hasSubheader && (subHeader.style.visibility = "")

  // example: SID_f0eb0659-19fa-4be5-940f-2451846b4def__FULL_SCREEN
  const key = `SID_${sessionId}__FULL_SCREEN`
  const message = {
    eventName: "screenshot-results",
    data: { [key]: screenshotUrl },
  }
  screenshotUrl && window.parent.postMessage(message, "*")
}

type EventType = MessageEvent<{
  eventName: string
  data: { sessionId: string }
}>

export const registerScreenshotListener = (externalPatient: string) => {
  const listener = (event: EventType) => {
    event.data?.eventName === "screenshot-clicked" &&
      void iteroSnapshot(externalPatient, event.data.data.sessionId)
  }
  window.addEventListener("message", listener)
  return () => window.removeEventListener("message", listener)
}
