import { createAction } from "typesafe-actions"

import { ImageControlsTypes } from "../types/imageControlsTypes"

import { ActiveFilter } from "library/common/reducers/imageControlsReducer"

export const setActiveFilter = createAction(
  ImageControlsTypes.SetActiveFilter
)<ActiveFilter>()
export const setActiveFilterSuccess = createAction(
  ImageControlsTypes.SetActiveFilterSuccess
)<ActiveFilter>()
export const setInitialState = createAction(
  ImageControlsTypes.SetInitialState
)()
export const setIsLargerAspectRatioScreen = createAction(
  ImageControlsTypes.SetIsLargerAspectRatioScreen
)<boolean>()

export const setIsSmallScreen = createAction(
  ImageControlsTypes.SetIsSmallScreen
)<boolean>()

export const setZoom = createAction(ImageControlsTypes.SetZoom)<number>()

export const setDimensions = createAction(ImageControlsTypes.SetDimensions)<{
  width: number
  height: number
}>()

export const toggleFullscreen = createAction(
  ImageControlsTypes.ToggleFullscreen
)()

export const setShowDynamicPbl = createAction(
  ImageControlsTypes.SetShowDynamicPbl
)<boolean>()

export const toggleIsMeasurementToolActive = createAction(
  ImageControlsTypes.ToggleIsMeasurementToolActive
)()

export const setShouldResetZoom = createAction(
  ImageControlsTypes.SetShouldResetZoom
)<boolean>()
export const setIsEditingPblOnRadiograph = createAction(
  ImageControlsTypes.SetIsEditingPblOnRadiograph
)<boolean>()
