import { PatientMatch } from "./patientTypes"

export enum ToastTypes {
  SetOpenToast = "@@Toast/SetOpenToast",
  RemoveOpenToast = "@@Toast/RemoveOpenToast",
}

export enum ToastType {
  Matches = "Matches",
  LinkedPatients = "LinkedPatients",
  CopiedToken = "CopiedToken",
  CopiedSessionId = "CopiedSessionId",
  ConnectIntegration = "ConnectIntegration",
  ConnectIntegrationError = "ConnectIntegrationError",
  InvalidUuid = "InvalidUuid",
  AlfaDocsReportSendFailure = "AlfaDocsReportSendFailure",
  AlfaDocsReportSendSuccess = "AlfaDocsReportSendSuccess",
  ImageUpload = "ImageUpload",
  SuccessfullyChangedPassword = "successfullyChangedPassword",
  ImageUploadError = "ImageUploadError",
}

export type ToastPopUp = Readonly<{
  type: ToastType
  message: string | React.ReactElement
  patientMatch?: PatientMatch
  notificationType: "error" | "success" | "information" | "warning"
  delay?: number
}>
