import { createSelector } from "reselect"
import deepmerge from "deepmerge"

import { RootState } from "core/store/configureStore"

import { getIsHighSenseModeActive } from "library/common/selectors/image"
import {
  getAdjustments,
  getMovingStack,
  getMovingAnnotation,
} from "library/common/selectors/adjustments"
import deepAnnotationsMerge from "library/utilities/deepAnnotationsMerge"
import mapAnnotationsByType from "library/utilities/mapAnnotationsByType"
import {
  filterSensAnnotations,
  isHighSensitivityMode,
} from "library/utilities/filterSensAnnotations"
import { getBonelossPro, getCariesPro } from "library/common/selectors/image"

import { FilterStatus } from "library/common/reducers/filtersReducer"

import {
  Detection,
  DetectionInfo,
} from "library/common/types/dataStructureTypes"

import { flipTeeth } from "@dentalxrai/transform-landmark-to-svg"
import { filterCaries, filterOldHsm } from "../../utilities/dataNormaliser"
import {
  AllAnnotations,
  AnnotationName,
  RestorationSubtype,
} from "../types/adjustmentTypes"
import { flipCroppedTeeth } from "../../utilities/tooth"
import {
  getFeatureCroppedTeeth,
  getFeatureImpacted,
  getFeatureNerveCanal,
} from "./features"
import { getFullMouthImages } from "./fullMouth"

/**
 * returns the raw entities as returned by the server.
 *
 * Warning: These do not consider if the image is flipped so they should only be used when this is desirable.
 * Normally use `getEntities` instead
 */
export const getRawEntities = (state: RootState) => state.entities
/**
 * returns the raw detected teeth as returned by the server.
 *
 * Warning: These do not consider if the image is flipped so they should only be used when this is desirable.
 * Normally use `getDetectedTeeth` instead
 */
export const getRawDetectedTeeth = (state: RootState) =>
  state.entities.detectedTeeth
export const getImageId = (state: RootState) => state.entities.imageId
export const getViewed = (state: RootState) => state.entities.viewed

// TODO + WARNING: due to a circular import error (between `serverData` & `entities` selectors)
//   we duplicated this selector.
//   You should only use the selector `getIsImageHorizontallyFlipped` from serverData instead of this one
//   as this will be removed once we figure out a refactoring strategy.
//   "Danke für Ihr Verständnis. wir arbeiten FÜR SIE."
export const getIsImageHorizontallyFlippedDuplicatedForEntities = (
  state: RootState
) => state.serverData.present.imageMeta.isImageHorizontallyFlipped
export const getAllUserChangesDuplicatedForEntities = (state: RootState) =>
  state.serverData.present.changes
export const getAllAdditionsDuplicatedForEntities = (state: RootState) =>
  state.serverData.present.additions

export const getHistoricalResults = (state: RootState) =>
  state.entities.historicalResults

const shapeMovingStack = (
  annotation: any,
  adjustments: any,
  nextMovingStack: any,
  label: string
) => {
  const nextAdjustments: any = deepmerge(adjustments, nextMovingStack)
  const adjTeethNums = Object.keys(nextAdjustments)

  const initAnnot = annotation.filter((initAnnot: any) => {
    const adjTooth = nextAdjustments[initAnnot.toothName]
    if (!(adjTooth?.annotations || {})[label]) return []

    return adjTooth.annotations[label].some(
      (adjAnnot: any) => adjAnnot.id !== initAnnot.id
    )
  })

  const annotToAdd = adjTeethNums.reduce((c: any, n: any) => {
    const adjAnnotsEls = (nextAdjustments[n]?.annotations || {})[label]
    if (!adjAnnotsEls) return c
    const toothNumAnnots = adjAnnotsEls.map((c: any) => ({
      ...c,
      toothName: Number(n),
    }))

    // eslint-disable-next-line
    return (c = c.concat(toothNumAnnots)), c
  }, [])

  const filteredInitAnnot = initAnnot.filter(
    (initAnnot: any) =>
      !annotToAdd.some((addAnnot: any) => addAnnot.id === initAnnot.id)
  )

  return [...filteredInitAnnot, ...annotToAdd].sort((a, b) =>
    a.id > b.id ? 1 : -1
  )
}

/**
 * Return the original list of annotations updating on-the-fly the
 * `toothName` considering whether the image is flipped or not.
 */
export const getEntities = createSelector(
  [
    getRawEntities,
    getIsImageHorizontallyFlippedDuplicatedForEntities,
    getFeatureNerveCanal,
    getFeatureImpacted,
    getFeatureCroppedTeeth,
  ],
  (
    entities,
    isFlipped,
    featureNerveCanal,
    featureImpacted,
    featureCroppedTeeth
  ) => {
    return {
      ...entities,
      caries: flipTeeth(filterCaries(entities.caries), isFlipped),
      apical: flipTeeth(filterOldHsm(entities.apical), isFlipped),
      restorations: flipTeeth(entities.restorations, isFlipped),
      calculus: flipTeeth(entities.calculus, isFlipped),
      nervus: flipTeeth(entities.nervus, isFlipped),
      nerveCanal:
        featureNerveCanal && !!entities.nerveCanal[0]
          ? [
              {
                ...entities.nerveCanal[0],
                toothCoordinates: flipTeeth(
                  entities.nerveCanal[0].toothCoordinates,
                  isFlipped
                ),
              },
            ]
          : [],
      segments: flipTeeth(entities.segments, isFlipped),
      impacted: featureImpacted ? flipTeeth(entities.impacted, isFlipped) : [],
      croppedTeeth: featureCroppedTeeth
        ? flipCroppedTeeth(entities.croppedTeeth, isFlipped)
        : undefined,
    }
  }
)

/**
 * Return the list of detected teeth, considering the isFlipped status of the image
 */
export const getDetectedTeeth = createSelector(
  [getRawDetectedTeeth, getIsImageHorizontallyFlippedDuplicatedForEntities],
  (detectedTeeth, isFlipped) => flipTeeth(detectedTeeth, isFlipped)
)

export const getDetections = createSelector(
  [getEntities, getFeatureImpacted],
  (entities, featureImpacted) =>
    entities.caries.concat(
      entities.apical,
      entities.restorations,
      entities.calculus,
      entities.nervus,
      featureImpacted ? entities.impacted : []
    )
)

export const getCaries = createSelector(
  [getEntities],
  (entities) => entities.caries
)
export const getApical = createSelector(
  [getEntities],
  (entities) => entities.apical
)
export const getRestorations = createSelector(
  [getEntities],
  (entities) => entities.restorations
)
export const getBoneloss = createSelector(
  [getEntities],
  (entities) => entities.boneloss
)
export const getNerveCanal = createSelector(
  [getEntities],
  (entities) => entities.nerveCanal
)

const getAdjustedCaries = createSelector(
  [
    getCaries,
    getAdjustments,
    getMovingStack,
    getMovingAnnotation,
    getCariesPro,
  ],
  (caries, adjustments, movingStack, movingAnnotation, cariesPro) => {
    const nextMovingStack =
      movingAnnotation === AllAnnotations.caries ? movingStack : {}
    const shapedCaries = shapeMovingStack(
      caries,
      adjustments,
      nextMovingStack,
      AnnotationName.caries
    )

    // remove location / depth unless we're in Caries Pro mode
    return cariesPro
      ? shapedCaries
      : shapedCaries.map((annot) => ({ ...annot, location: "", depth: "" }))
  }
)

const getAdjustedApical = createSelector(
  [getApical, getAdjustments, getMovingStack, getMovingAnnotation],
  (apical, adjustments, movingStack, movingAnnotation) => {
    const nextMovingStack =
      movingAnnotation === AllAnnotations.apical ? movingStack : {}

    return shapeMovingStack(
      apical,
      adjustments,
      nextMovingStack,
      AnnotationName.apical
    )
  }
)

const getAdjustedRestorations = createSelector(
  [getRestorations, getAdjustments, getMovingStack, getMovingAnnotation],
  (restorations, adjustments, movingStack, movingAnnotation) => {
    const restors = [
      RestorationSubtype.bridges,
      RestorationSubtype.crowns,
      RestorationSubtype.fillings,
      RestorationSubtype.implants,
      RestorationSubtype.roots,
    ]
    const isInRestorations = restors.some(
      (label: string) => label === movingAnnotation
    )
    const nextMovingStack = isInRestorations ? movingStack : {}

    return shapeMovingStack(
      restorations,
      adjustments,
      nextMovingStack,
      AnnotationName.restorations
    )
  }
)

const getMappedAnnotations = createSelector(
  [getAdjustedCaries, getAdjustedApical, getAdjustedRestorations],
  (caries, apical, restorations) => {
    const mappedCaries = mapAnnotationsByType(caries, AnnotationName.caries)
    const mappedApical = mapAnnotationsByType(apical, AnnotationName.apical)
    const mappedRestorations = mapAnnotationsByType(
      restorations,
      AnnotationName.restorations
    )

    return [mappedCaries, mappedApical, mappedRestorations]
  }
)

export const getEnabledMappedAnnotations = createSelector(
  [
    getMappedAnnotations,
    getIsHighSenseModeActive,
    getAllUserChangesDuplicatedForEntities,
  ],
  (collections, isHighSense, acceptedChanges) => {
    const cariesCollection = filterSensAnnotations(
      collections[0],
      isHighSense,
      acceptedChanges
    )
    const apicalCollection = filterSensAnnotations(
      collections[1],
      isHighSense,
      acceptedChanges
    )
    const otherCollection = collections[2]
    const dataToProcess = [cariesCollection, apicalCollection, otherCollection]

    return deepAnnotationsMerge(dataToProcess)
  }
)

export const getDetectionVisibility = createSelector(
  [
    getEntities,
    getIsHighSenseModeActive,
    getAllUserChangesDuplicatedForEntities,
  ],
  (entities, isHighSenseModeActive, userChanges) => {
    const visibleForHSM = (
      name: string,
      subtype: string,
      id: number
    ): boolean =>
      // HSM is only available on caries / apical lesions
      ![AnnotationName.caries, AnnotationName.apical].includes(
        name as AnnotationName
      ) ||
      isHighSenseModeActive === isHighSensitivityMode(subtype) ||
      userChanges.some(
        (change) => change.annotationId === id && change.action === "accepted"
      )

    const rejected = new Set(
      userChanges
        .filter((change) => change.action === "rejected")
        .map((change) => change.annotationId)
    )
    const names = [
      AnnotationName.restorations,
      AnnotationName.caries,
      AnnotationName.calculus,
      AnnotationName.nervus,
      AnnotationName.nerveCanal,
      AnnotationName.impacted,
      AnnotationName.apical,
    ]
    return names.flatMap((name): DetectionInfo[] =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      entities[name].map((detection: Detection) => ({
        visible:
          !rejected.has(detection.id) &&
          visibleForHSM(name, detection.subtype || "", detection.id),
        name,
        detection,
      }))
    )
  }
)

const parseFilterName = (type: AnnotationName) => {
  switch (type) {
    case AnnotationName.apical:
      return FilterStatus.ShowApical
    case AnnotationName.caries:
      return FilterStatus.ShowCaries
    case AnnotationName.calculus:
    case AnnotationName.restorations:
    case AnnotationName.nervus:
    case AnnotationName.impacted:
      return FilterStatus.ShowOther
    case AnnotationName.nerveCanal:
      return FilterStatus.ShowNerveCanal
    default:
      return []
  }
}

export const getAvailableFilters = createSelector(
  [
    getDetectionVisibility,
    getAllAdditionsDuplicatedForEntities,
    getBonelossPro,
    getFullMouthImages,
  ],
  (entities, additions, bonelossPro, fullMouthImages): Set<FilterStatus> => {
    if (bonelossPro) {
      return new Set()
    }

    /*
      Extract the entry names if any object has at least one entry where the entry's array has values.
      Returns a unique array of entry names.
    */
    const extractEntryNames = (
      objectsArray: { apical: string[]; caries: string[]; other: string[] }[]
    ): FilterStatus[] => {
      const entryNames: Set<FilterStatus> = new Set()

      objectsArray.forEach((obj) => {
        if (obj.apical.length > 0) {
          entryNames.add(FilterStatus.ShowApical)
        }
        if (obj.caries.length > 0) {
          entryNames.add(FilterStatus.ShowCaries)
        }
        if (obj.other.length > 0) entryNames.add(FilterStatus.ShowOther)
      })

      return Array.from(entryNames)
    }

    const allFilters =
      fullMouthImages.images.length > 0
        ? extractEntryNames(fullMouthImages.images.map((f) => f.masks))
        : entities
            .filter((e) => e.visible)
            .flatMap((e) => parseFilterName(e.name as AnnotationName))
            .concat(
              additions
                .filter((a) => a.mask)
                .flatMap((a) => parseFilterName(a.type))
            )
    return new Set(allFilters)
  }
)

/** Use it to know whether there is a blob/mask that will be display on the xray image */
export const getImageHasCalculus = createSelector(
  [getEntities, getAllUserChangesDuplicatedForEntities],
  (entities, userChanges) => {
    const rejected = new Set(
      userChanges
        .filter((change) => change.action === "rejected")
        .map((change) => change.annotationId)
    )
    return !!entities.calculus.filter(
      (detection) => !rejected.has(detection.id)
    ).length
  }
)
