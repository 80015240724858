import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  all,
} from "redux-saga/effects"
import { toggleTeethAreShifting } from "../actions/adjustments"

import {
  setActiveAddition,
  setActiveCariesProId,
  setActivePeriId,
  setDrawingAction,
  setDrawingAnnotation,
  setShowDrawAnnotationButton,
  setShowDrawingWarning,
} from "../actions/drawing"
import { setActiveFilter } from "../actions/imageControls"
import { setDataIsChanged } from "../actions/saving"
import {
  addUserAdditions,
  addUserChanges,
  deleteUserAdditionByIdSuccess,
  deleteUserChange,
} from "../actions/serverData"
import { setActiveTooth } from "../actions/teeth"
import { rememberState } from "../actions/withHistory"
import { ActiveFilter } from "../reducers/imageControlsReducer"
import { getTeethAreShifting } from "../selectors/adjustments"
import {
  getActiveCariesProId,
  getDrawingAction,
  getDrawingAnnotation,
  getIsErasing,
  getMasksToDraw,
  getIsDrawingModeActive,
} from "../selectors/drawing"
import {
  getActiveAddition,
  getAllAdditions,
  getDetectionsToRenderForAllTeeth,
  getAnnotationsToRenderForAllTeeth,
  getAllUserChanges,
  getShowLetterBasedPeri,
} from "../selectors/serverData"
import { getActiveTooth } from "../selectors/teeth"
import {
  AnnotationName,
  AnnotationOnTooth,
  RestorationSubtype,
} from "../types/adjustmentTypes"
import { Detection } from "../types/dataStructureTypes"
import {
  DrawingAction,
  DrawingAnnotation,
  DrawingTypes,
} from "../types/drawing"
import TeethTypes from "../types/teethTypes"
import { disableAnnotationCombinationsBySubtype } from "./adjustmentsSaga"
import { UserChange } from "library/common/types/dataStructureTypes"
import { AnnotationToRender } from "../types/serverDataTypes"

function* setDrawingActionSaga() {
  const drawingAction: DrawingAction = yield select(getDrawingAction)
  const isDrawingModeActive: boolean = yield select(getIsDrawingModeActive)

  if (!isDrawingModeActive) {
    return
  }

  const activeTooth: number = yield select(getActiveTooth)
  const annotationType =
    AnnotationName[drawingAction as keyof typeof AnnotationName] ||
    AnnotationName.restorations
  const masksToDraw: AnnotationOnTooth[] = yield select(getMasksToDraw)
  const activeAddition = {
    toothName: drawingAction === DrawingAction.annotate ? 0 : activeTooth,
    type: annotationType,
    subtype:
      RestorationSubtype[drawingAction as keyof typeof RestorationSubtype],
    id: masksToDraw[0]?.id,
    mask: masksToDraw[0]?.mask,
    ids: masksToDraw
      .flatMap((a) => a.id || [])
      .concat(masksToDraw[0]?.rejectedIds || []),
    isReplacedSubtype: masksToDraw[0]?.isReplacedSubtype,
  }
  yield put(setShowDrawingWarning(false))
  yield put(
    setActiveAddition(
      drawingAction === DrawingAction.select ? null : activeAddition
    )
  )
}

function* toggleActiveDrawingModeSaga() {
  const isDrawingModeActive: boolean = yield select(getIsDrawingModeActive)
  const showLetterBasedPeri: boolean = yield select(getShowLetterBasedPeri)
  const drawingAction: DrawingAction = yield select(getDrawingAction)
  const drawingAnnotation: DrawingAnnotation =
    yield select(getDrawingAnnotation)

  yield put(setActiveFilter(ActiveFilter.Navigation))
  yield put(setShowDrawAnnotationButton(false)) // Hide the draw annotation button when drawing mode is active

  if (!isDrawingModeActive) {
    yield put(setShowDrawingWarning(false))
    yield put(setActiveAddition(null))
    yield put(setActiveAddition(null))
    yield put(setActiveCariesProId(null))
    yield put(setActivePeriId(null))
    yield put(setDrawingAnnotation(DrawingAnnotation.none))
    if (showLetterBasedPeri) {
      yield put(setActiveTooth(null))
    }
  } else if (!showLetterBasedPeri && !!drawingAnnotation) {
    yield put(setDrawingAction(DrawingAction[drawingAnnotation]))
  } else {
    yield put(setDrawingAction(drawingAction))
  }

  const teethAreShifting: boolean = yield select(getTeethAreShifting)
  if (!teethAreShifting) return
  yield put(toggleTeethAreShifting())
}

function* saveDrawingAnnotationSaga() {
  const isDrawingModeActive: boolean = yield select(getIsDrawingModeActive)
  const activeAddition: AnnotationOnTooth = yield select(getActiveAddition)
  if (!isDrawingModeActive || (!activeAddition?.id && !activeAddition?.mask)) {
    return
  }

  const drawingAction: DrawingAction = yield select(getDrawingAction)
  const annotationType =
    AnnotationName[drawingAction as keyof typeof AnnotationName] ||
    AnnotationName.restorations
  const subtype =
    RestorationSubtype[drawingAction as keyof typeof RestorationSubtype]
  const allAdditions: AnnotationOnTooth[] = yield select(getAllAdditions)

  // Delete user addition if annotation exists in additions and no mask is present (when erasing the whole annotation).
  const isErasing: boolean = yield select(getIsErasing)
  const cariesProId: number | null = yield select(getActiveCariesProId)
  const searchId = cariesProId || activeAddition.id

  if (
    !activeAddition.mask &&
    isErasing &&
    searchId &&
    allAdditions.some((a) => searchId === a.id)
  ) {
    yield put(deleteUserAdditionByIdSuccess(searchId))
    return
  }

  // Return if the addition already exists with the same mask
  // TODO: improve this detection with an additional attribute to the active addition
  if (
    activeAddition.id &&
    allAdditions.some(
      (a) => activeAddition.id === a.id && activeAddition.mask === a.mask
    )
  ) {
    return
  }

  yield put(rememberState())

  const detectionsToRenderForAllTeeth: Detection[] = yield select(
    getDetectionsToRenderForAllTeeth
  )

  // All active detections that are not already rejected
  const activeAnnotations = detectionsToRenderForAllTeeth.filter((d) =>
    activeAddition.ids?.includes(d.id)
  )

  // Reject AI detections to allow drawing from masksToDraw
  const rejectedIds = activeAnnotations.map((a) => a.id)
  const rejections: UserChange[] = activeAnnotations.map((a) => ({
    action: "rejected",
    annotationId: a.id,
  }))
  const changes: UserChange[] = yield select(getAllUserChanges)

  /*
  First delete all changes that are part of rejectedIds (this can include annotations like "accepted"),
  then add the new addition as a rejection.
  */
  yield all(
    changes.map((c) => {
      if (rejectedIds.includes(c.annotationId)) {
        return put(
          deleteUserChange({
            id: c.annotationId,
          })
        )
      }
    })
  )
  yield put(addUserChanges(rejections))

  // When erasing the entire AI detection, don't add addition. It is important that this happens after the rejection.
  if (!activeAddition.mask) return

  const extraIds = activeAddition.ids?.slice(1) || []

  // copy depth / location from existing state
  const allAnnotations: AnnotationToRender[] = yield select(
    getAnnotationsToRenderForAllTeeth
  )
  const affectedAnnotations = allAnnotations.filter(
    (a) => a.id && activeAddition.ids?.includes(a.id)
  )
  const depth = affectedAnnotations.find((a) => a.depth)?.depth
  const location =
    affectedAnnotations
      .filter((a) => a.location)
      .map((annotation) => annotation.location)
      .sort()
      .reverse()
      .join("") ||
    (activeAddition.type == AnnotationName.calculus ? "md" : undefined)

  const newAdditions: AnnotationOnTooth[] = [
    {
      ...activeAddition,
      ...(depth && { depth }),
      ...(location && { location }),
      ...(rejectedIds.length && { rejectedIds }),
      ids: undefined,
      id:
        activeAddition.id && rejectedIds.includes(activeAddition.id)
          ? undefined
          : activeAddition.id,
    },
    ...allAdditions
      .filter((a) => a.id && a.mask && extraIds.includes(a.id))
      .map((a) => ({ ...a, mask: undefined })), // remove mask from all additions except the first
  ]
  yield put(addUserAdditions(newAdditions))

  // Handle mutually exclusive annotations
  yield call(
    disableAnnotationCombinationsBySubtype,
    subtype || AnnotationName[annotationType]
  )

  // Allow saving
  yield put(setDataIsChanged(true))
}

export default function* drawingSaga() {
  yield takeEvery(
    [DrawingTypes.SetDrawingAction, TeethTypes.SetActiveTooth],
    setDrawingActionSaga
  )
  yield takeLatest(
    DrawingTypes.ToggleActiveDrawingMode,
    toggleActiveDrawingModeSaga
  )
  yield takeLatest(
    DrawingTypes.SaveDrawingAnnotation,
    saveDrawingAnnotationSaga
  )
}
