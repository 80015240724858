import { createAction } from "typesafe-actions"
import {
  DismissedNotification,
  SaveComplete,
  SavingData,
  SavingTypes,
} from "../types/savingTypes"
import { ResultStatus } from "../types/dataStructureTypes"

export const requestSendChanges = createAction(
  SavingTypes.RequestSendChanges
)<SavingData>()
export const requestSendChangesComplete = createAction(
  SavingTypes.RequestSendChangesComplete
)<SaveComplete>()
export const setSavingResultStatus = createAction(
  SavingTypes.SetSavingResultStatus
)<ResultStatus>()
export const setDataIsChanged = createAction(
  SavingTypes.SetDataIsChanged
)<boolean>()
export const requestSendEvent = createAction(
  SavingTypes.RequestSendEvent
)<string>()
export const setNotificationDismissed = createAction(
  SavingTypes.SetNotificationDismissed
)<DismissedNotification>()
