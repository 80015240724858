import { createAction } from "typesafe-actions"

import {
  ServerDataTypes,
  Comment,
  AnnotationChange,
  IMeta,
  IMoveComment,
  IInferenceStatus,
  BoneLossFormUser,
  ImageFilterTypes,
  INote,
} from "library/common/types/serverDataTypes"
import { UserChange } from "../types/dataStructureTypes"
import { AnnotationOnTooth, BonelossChange } from "../types/adjustmentTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"

export const addUserChanges = createAction(ServerDataTypes.AddUserChanges)<
  UserChange[]
>()
export const deleteUserChange = createAction(ServerDataTypes.DeleteUserChange)<{
  id: number
  deleteNonHSM?: boolean
}>()
export const editUserChange = createAction(ServerDataTypes.EditUserChange)<{
  oldChange: UserChange
  newChange: UserChange
}>()
export const addUserAdditions = createAction(ServerDataTypes.AddUserAdditions)<
  AnnotationOnTooth[]
>()
export const addCariesAdditions = createAction(
  ServerDataTypes.AddCariesAdditions
)<AnnotationOnTooth[]>()
export const changeUserAddition = createAction(
  ServerDataTypes.ChangeUserAddition
)<AnnotationOnTooth>()
export const deleteUserAddition = createAction(
  ServerDataTypes.DeleteUserAddition
)<AnnotationOnTooth>()
export const deleteUserAdditionById = createAction(
  ServerDataTypes.DeleteUserAdditionById
)<number>()
export const deleteUserAdditionByIdSuccess = createAction(
  ServerDataTypes.DeleteUserAdditionByIdSuccess
)<number>()
export const userAddAddedTeeth = createAction(
  ServerDataTypes.UserAddAddedTeeth
)<Tooth[]>()
export const userDeleteAddedTeeth = createAction(
  ServerDataTypes.UserDeleteAddedTeeth
)<number>()
export const userAddDeletedTeeth = createAction(
  ServerDataTypes.UserAddDeletedTeeth
)<Tooth[]>()
export const userDeleteDeletedTeeth = createAction(
  ServerDataTypes.UserDeleteDeletedTeeth
)<number>()
export const setMovedTeeth = createAction(ServerDataTypes.SetMovedTeeth)<
  Record<string, number>
>()
export const deleteMovedUserChange = createAction(
  ServerDataTypes.DeleteMovedUserChange
)<number>()
export const setInitialState = createAction(ServerDataTypes.SetInitialState)()
export const setInitialImageMetaState = createAction(
  ServerDataTypes.SetInitialImageMetaState
)()
export const setGeneralComment = createAction(
  ServerDataTypes.SetGeneralComment
)<string>()
export const addAddedComments = createAction(ServerDataTypes.AddAddedComments)<
  Comment[]
>()
export const addNote = createAction(ServerDataTypes.AddNote)()
export const setNotes = createAction(ServerDataTypes.SetNotes)<INote[]>()
export const setToothLoss = createAction(ServerDataTypes.SetTeethLoss)<string>()
export const setBoneLossCategory = createAction(
  ServerDataTypes.SetBonelossCategory
)<string>()
export const setBoneLossIndex = createAction(
  ServerDataTypes.SetBonelossIndex
)<string>()
export const setAge = createAction(ServerDataTypes.SetAge)<number>()
export const setDiabetes = createAction(ServerDataTypes.SetDiabetes)<string>()
export const setSmoking = createAction(ServerDataTypes.SetSmoking)<string>()
export const setDistribution = createAction(
  ServerDataTypes.SetExtendedOrDistribution
)<string>()
export const setComplications = createAction(
  ServerDataTypes.SetComplexity
)<string>()
export const changeAnnotation = createAction(
  ServerDataTypes.ChangeAnnotation
)<AnnotationChange>()
export const toggleAnnotation = createAction(
  ServerDataTypes.ToggleAnnotation
)<number>()
export const flipImage = createAction(ServerDataTypes.FlipImage)()
export const toggleFlipImage = createAction(ServerDataTypes.ToggleFlipImage)()
export const saveImageMeta = createAction(
  ServerDataTypes.SaveImageMeta
)<IMeta>()
export const saveBoneLossForm = createAction(
  ServerDataTypes.SaveBonelossForm
)<BoneLossFormUser>()
export const moveComments = createAction(
  ServerDataTypes.MoveComments
)<IMoveComment>()
export const setToothBoneloss = createAction(
  ServerDataTypes.SetToothBoneloss
)<BonelossChange>()
export const toggleDisplayHorizontallyFlipped = createAction(
  ServerDataTypes.ToggleDisplayHorizontallyFlipped
)()
export const setInferenceStatus = createAction(
  ServerDataTypes.SetInferenceStatus
)<IInferenceStatus>()
export const setPatientUuid = createAction(
  ServerDataTypes.SetPatientUuid
)<string>()
export const adjustFilter = createAction(ServerDataTypes.AdjustFilter)<{
  type: ImageFilterTypes
  value: number
}>()
