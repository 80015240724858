import { all, fork } from "redux-saga/effects"

import authenticateSaga from "library/common/sagas/authenticateSaga"
import uploadSaga from "library/common/sagas/uploadSaga"
import imageControlsSaga from "library/common/sagas/imageControlsSaga"
import xraysSaga from "library/common/sagas/xraysSaga"
import imageSaga from "library/common/sagas/imageSaga"
import adjustmentsSaga from "library/common/sagas/adjustmentsSaga"
import webSocketSaga from "library/common/sagas/webSocketSaga"
import teethSaga from "library/common/sagas/teethSaga"
import serverDataSaga from "library/common/sagas/serverDataSaga"
import savingDataSaga from "library/common/sagas/savingSaga"
import filtersSaga from "library/common/sagas/filtersSaga"
import drawingSaga from "library/common/sagas/drawingSaga"
import legendSaga from "library/common/sagas/legendSaga"
import patientSaga from "library/common/sagas/patientSaga"
import fullMouthSaga from "library/common/sagas/fullMouthSaga"

export default function* coreSaga() {
  yield all([
    fork(authenticateSaga),
    fork(uploadSaga),
    fork(imageControlsSaga),
    fork(xraysSaga),
    fork(adjustmentsSaga),
    fork(imageSaga),
    fork(webSocketSaga),
    fork(teethSaga),
    fork(serverDataSaga),
    fork(savingDataSaga),
    fork(filtersSaga),
    fork(drawingSaga),
    fork(legendSaga),
    fork(patientSaga),
    fork(fullMouthSaga),
  ])
}
