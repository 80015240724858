import { CreateRadiographSet } from "library/common/types/patientTypes"
import fetch from "../utilities/fetch"

export const requestDeleteXRay = (id: string) => fetch.delete(`/result/${id}/`)
export const requestCreateRadiographSet = (data: CreateRadiographSet) => {
  return fetch.post(`/radiograph-set/`, data)
}
export const requestUpdateRadiographSet = (
  id: string,
  data: CreateRadiographSet
) => fetch.put(`/radiograph-set/${id}/`, data)

export const requestRemoveRadiographSet = (id: string) =>
  fetch.delete(`/radiograph-set/${id}/`)

export const requestGetRadiographSet = (id: string) =>
  fetch.get(`/radiograph-set/${id}/`)
