import { IAnnotations } from "./serverDataTypes"

export enum AdjustmentTypes {
  AdjustAnnotations = "@@Adjustments/AdjustAnnotations",
  AdjustAnnotationsSuccess = "@@Adjustments/AdjustAnnotationsSuccess",
  MoveAnnotation = "@@Adjustments/MoveAnnotation",
  MoveAnnotationSuccess = "@@Adjustments/MoveAnnotationSuccess",
  MoveAnnotationTo = "@@Adjustments/MoveAnnotationTo",
  MoveAnnotationToSuccess = "@@Adjustments/MoveAnnotationToSuccess",
  ToggleAnnotation = "@@Adjustments/ToggleAnnotation",
  ToggleAnnotationOnTooth = "@@Adjustments/ToggleAnnotationOnTooth",
  TogglePeriAnnotation = "@@Adjustments/TogglePeriAnnotation",
  ToggleAnnotationSuccess = "@@Adjustments/ToggleAnnotationSuccess",
  StartExpanding = "@@Adjustments/StartExpanding",
  StartExpandingSuccess = "@@Adjustments/StartExpandingSuccess",
  ExpandShiftingTo = "@@Adjustments/ExpandShiftingTo",
  ExpandShiftingToSuccess = "@@Adjustments/ExpandShiftingToSuccess",
  MoveShiftingStackTo = "@@Adjustments/MoveShiftingStackTo",
  SetNextActiveStack = "@@Adjustments/SetNextActiveStack",
  SetNextActiveStackSuccess = "@@Adjustments/SetNextActiveStackSuccess",
  SetInitialState = "@@Adjustments/SetInitialState",
  ToggleTeethAreShifting = "@@Adjustments/ToggleTeethAreShifting",
}

// AnnotationName enum key-value pairs to be camelCase as it is used with state objects
export enum AnnotationName {
  caries = "caries",
  apical = "apical",
  restorations = "restorations",
  restoration = "restoration", // Old type, kept for legacy support.
  calculus = "calculus",
  nervus = "nervus",
  impacted = "impacted",
  annotate = "annotate",
  nerveCanal = "nerveCanal",
}

// RestorationSubtype key-value pairs to be camelCase as it is used with state objects
export enum RestorationSubtype {
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
  implants = "implants",
  roots = "roots",
}

// AllAnnotations key-value pairs to be camelCase as it is used with state objects
export enum AllAnnotations {
  none = "",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  nervus = "nervus",
  impacted = "impacted",
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
  implants = "implants",
  roots = "roots",
}

export enum CariesDepth {
  Enamel = "e",
  Dentin = "d",
  Unknown = "",
}

export enum CroppedTeethDirection {
  Left = "l",
  Right = "r",
  LeftRight = "lr",
  None = "",
}

// AllAnnotationsLegend enum values to be camelCase as it is used with state objects
export enum AllAnnotationsLegend {
  None = "",
  Caries = "caries",
  Apical = "apical",
  Calculus = "calculus",
  Nervus = "nervus",
  Impacted = "impacted",
  Bridge = "bridge",
  Crown = "crown",
  Filling = "filling",
  Implant = "implant",
  Root = "root",
  NervusLong = "nervusLong",
}

// AllConfirmedAnnotations enum values to be camelCase as it is used with state objects
export enum AllConfirmedAnnotations {
  Caries = "caries",
  Apical = "apical",
  Calculus = "calculus",
  Boneloss = "boneloss",
}

export type AnnotationOnTooth = Readonly<{
  toothName: number
  type: AnnotationName
  subtype?: RestorationSubtype

  // Caries Pro:
  location?: string
  depth?: CariesDepth
  id?: number // we need to distinguish between the user additions
  mask?: string | undefined
  ids?: number[] | null

  rejectedIds?: number[] // referencing some AI detections that are rejected by this drawing
  isReplacedSubtype?: boolean // Only for when we replace a crown/bridge with a crown/bridge
}>

export type MoveAnnotation = { [key: number]: { annotations: IAnnotations } }

export interface ShiftingSelection {
  start: number
  to: number
}

export type PeriAnnotation = Readonly<{
  annotationId: number
  isChecked: boolean
}>

export interface ShiftingBounds extends ShiftingSelection {
  upper: boolean
}

export type BonelossAnnotation = Readonly<{
  d?: number // distal
  m?: number // mesial
  toothName: number
  landmarks: Landmark
}>

export type BoneLossAdjustment = Readonly<{
  d?: number[] // Always in sequence (CEJ, PBL, apex)
  m?: number[]
}>

export interface BonelossChange {
  d?: number // distal
  m?: number // mesial
  toothName: number
  isChecked: boolean
  landmarks?: Landmark
  aiLandmarks?: Landmark
  adjusted: BoneLossAdjustment
}

export interface Landmark {
  d?: number[][]
  m?: number[][]
}

export type ActivePblLandmark = Readonly<{
  landmarkPoints: number[][]
  aiLandmarkPoints: number[][]
  adjusted: BoneLossAdjustment
}>

export type PblLandmarkStart = Readonly<{
  mouseX: number
  mouseY: number

  landmarkIndex: number // 0 - 5 index into the landmarkPoints
  startValue: number
  syncedApexStartValue?: number // for synced apex teeth: the other start value
}>

export enum ScrollIconPosition {
  Top,
  Bottom,
  Both,
}

export type ScrollIconData = Readonly<{
  clientHeight: number
  scrollHeight: number
  scrollTop: number
  offsetWidth: number
}>

export type TeethPosition = Readonly<1 | 2 | 3 | 4 | 5 | 6 | 7 | 8>
