export enum ImageControlsTypes {
  SetZoom = "@@ImageControls/SetZoom",
  SetDimensions = "@@ImageControls/SetDimensions",
  SetActiveFilter = "@@ImageControls/SetActiveFilter",
  SetActiveFilterSuccess = "@@ImageControls/SetActiveFilterSuccess",
  SetInitialState = "@@ImageControls/SetInitialState",
  ToggleFullscreen = "@@ImageControls/ToggleFullscreen",
  SetIsLargerAspectRatioScreen = "@@ImageControls/SetIsLargerAspectRatioScreen",
  SetIsSmallScreen = "@@ImageControls/SetIsSmallScreen",
  SetShowDynamicPbl = "@@ImageControls/SetShowDynamicPbl",
  ToggleIsMeasurementToolActive = "@@ImageControls/ToggleIsMeasurementToolActive",
  SetShouldResetZoom = "@@ImageControls/SetShouldResetZoom",
  SetIsEditingPblOnRadiograph = "@@ImageControls/SetIsEditingPblOnRadiograph",
}
