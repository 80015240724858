export enum SavingTypes {
  RequestSendChanges = "@@Saving/RequestSendChanges",
  RequestSendChangesComplete = "@@Saving/RequestSendChangesComplete",
  SetDataIsChanged = "@@Saving/SetDataIsChanged",
  RequestSendEvent = "@@Saving/RequestSendEvent",
  SetSavingResultStatus = "@@Saving/SetSavingResultStatus",
  SetNotificationDismissed = "@@Saving/SetNotificationDismissed",
}

export interface SaveComplete {
  success: boolean
  savedWithButton: boolean
  id: string
}

export enum SavingType {
  All = "All",
  ImageMetaData = "ImageMetaData",
}
export enum DismissalType {
  AutoRotationDismissed = "AutoRotationDismissed",
  OrthoConditionDismissed = "OrthoConditionDismissed",
}
export interface DismissedNotification {
  id: string
  type: DismissalType
}

export interface SavingData {
  savingChanges: SavingType
  savedWithButton?: boolean
  resultId: string
}
