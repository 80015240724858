import { createAction } from "typesafe-actions"
import { BreadcrumbTypes } from "../types/breadcrumbTypes"

export const setInitialBreadcrumbState = createAction(
  BreadcrumbTypes.SetInitialBreadcrumbState
)()
export const setPatientFileBreadcrumb = createAction(
  BreadcrumbTypes.SetPatientFileBreadcrumb
)<string>()
export const setImageIDBreadcrumb = createAction(
  BreadcrumbTypes.SetImageIdBreadcrumb
)<string>()
export const setReportBreadcrumb = createAction(
  BreadcrumbTypes.SetReportBreadcrumb
)<string>()
