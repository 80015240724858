import {
  call,
  put,
  take,
  fork,
  takeLatest,
  race,
  takeEvery,
  select,
  cancel,
} from "redux-saga/effects"
import { eventChannel, END, EventChannel, Task } from "redux-saga"
import { WebSocketTypes } from "library/common/types/webSocketTypes"
import * as webSocketActions from "library/common/actions/webSocket"
import * as imageActions from "library/common/actions/image"
import {
  IAnalysisResult,
  refreshPatientFile,
  updateImageDataSaga,
} from "./imageSaga"
import { requestImageAnalysis } from "library/services/imageApis"
import { FilterTypes } from "../types/filterTypes"
import { UserTypes } from "../types/userTypes"
import { AdjustmentTypes } from "../types/adjustmentTypes"
import { ServerDataTypes } from "../types/serverDataTypes"
import { ImageTypes } from "../types/imageTypes"
import TeethTypes from "../types/teethTypes"
import { browser } from "library/utilities/browser"
import { getContextQueryParams } from "../selectors/user"
import { ContextQuery } from "../types/userTypes"
import {
  getFeatureUploadFromPatientFile,
  getRevertVersion,
} from "../selectors/features"
import {
  getActiveFullMouthImage,
  getCurrentFmxSet,
} from "../selectors/fullMouth"
import { CurrentFmx } from "../types/fullMouthTypes"
import { isDashboard } from "library/utilities/urls"
import { setShouldFetchLatestFullMouth } from "../actions/fullMouth"
import { updateActivePatientImageStatus } from "../actions/patient"
import { ICurrentBatchUpload } from "../types/uploadTypes"
import { getCurrentBatchUpload } from "../selectors/upload"
import { ActivePatientImages } from "../types/patientTypes"
import { getActivePatientResultImages } from "../selectors/patient"

function createWebSocketConnection(endpoint: string) {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(endpoint)
    socket.onopen = () => resolve(socket)
    socket.onerror = (evt) => reject(evt)
  })
}

function createSocketChannel(socket: any) {
  return eventChannel((emit) => {
    socket.onmessage = (event: any) => emit(event.data)
    socket.onclose = () => emit(END)
    const unsubscribe = () => (socket.onmessage = null)

    return unsubscribe
  })
}

function* handleMessagesSaga(data: any, socket: WebSocket) {
  yield put(imageActions.imageProcessingComplete(data.id))

  socket.close()
}

function* listenForSocketMessages(imageId: string) {
  let socket: WebSocket | undefined
  let socketChannel: EventChannel<null> | undefined

  try {
    socket = yield call(() =>
      createWebSocketConnection(process.env.REACT_APP_API_URL_WS!)
    )
    socketChannel = yield call(createSocketChannel, socket)

    console.log("successfully connected to websocket")

    socket?.send(imageId)

    /*
    Check if status changed before websocket connected in order to set all the changed
    values (especially isProcessed and isOwner). Setting isProcessed will avoid infinite
    loading state
    */
    const params: ContextQuery = yield select(getContextQueryParams)
    const revertVersion: boolean = yield select(getRevertVersion)
    const { data }: IAnalysisResult = yield call(
      requestImageAnalysis,
      imageId,
      { ...params, showHistory: revertVersion }
    )
    if (data.status === "done") {
      yield call(updateImageDataSaga, data)
      return
    }

    while (true) {
      const payload: string = yield take(socketChannel as any)
      const parsedPayload = JSON.parse(payload)
      yield fork(handleMessagesSaga as any, parsedPayload, socket)
    }
  } catch (error) {
    console.error("error while connecting to websocket")
  } finally {
    socketChannel?.close()
    socket?.close()
  }
}

function* connectSaga({
  payload: imageId,
}: ReturnType<typeof webSocketActions.connect>) {
  const featureUploadFromPatientFile: boolean = yield select(
    getFeatureUploadFromPatientFile
  )
  if (featureUploadFromPatientFile) {
    const socketTask: Task[] = yield call(listenForSocketMessages, imageId)
    yield cancel(socketTask)
  }
  if (!featureUploadFromPatientFile) {
    // Listen for socket messages, but if we navigate away, cancel the process.
    yield race({
      task: call(listenForSocketMessages, imageId),
      cancel: take("@@router/LOCATION_CHANGE"),
    })
  }

  console.log("successfully disconnected")
}

let UITrackingSocket: WebSocket | undefined

function* connectUIEventsSaga() {
  // Ensure UI tracking websocket is opened once
  if (!!UITrackingSocket || !process.env.REACT_APP_UI_EVENTS_WS) return

  try {
    UITrackingSocket = yield call(() =>
      createWebSocketConnection(
        `${process.env.REACT_APP_UI_EVENTS_WS}?token=${sessionStorage.getItem(
          "access_token"
        )}`
      )
    )

    console.log("successfully connected to UI events tracking websocket")
  } catch (error) {
    console.error("error while connecting to UI events tracking websocket")
  }
}

function* UIEventsSaga({
  payload: event,
  type,
}: ReturnType<typeof webSocketActions.UIEvents>) {
  const { width, height } = window.screen
  // For some actions / toggles without a payload, we pass a string as an action to prevent unwanted payload issues
  const toggles = [
    "@@User/ToggleCariesPro",
    "@@User/ToggleBonelossPro",
    "@@Filters/ToggleHsm",
    "@@ServerData/FlipImage",
    "@@Image/ReanalyzeImage",
    "@@Image/OpenPdfReport",
  ]

  UITrackingSocket?.send(
    JSON.stringify({
      timestamp: Date.now() / 1e3, // timestamp should be sent as a float
      key: type,
      payload: toggles.includes(type)
        ? { action: "toggle / click action" }
        : { event },
      meta: {
        screenWidth: width,
        screenHeight: height,
        browser,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
  )
}

function* setEventSourceMessageSaga({
  payload,
}: ReturnType<typeof webSocketActions.setEventSourceMessage>) {
  const activeFullMouthImage: string | null = yield select(
    getActiveFullMouthImage
  )
  const currentFmxSet: CurrentFmx = yield select(getCurrentFmxSet)
  const { event_type, object_type } = payload

  /*
    Handle batch upload analysis progress. We fetch the latest results when the
    batch upload is done in order to get metadata like image modalities.
  */
  if (
    payload.object_type === "radiograph" &&
    payload.result_id &&
    event_type === "update"
  ) {
    yield put(
      updateActivePatientImageStatus({
        id: payload.result_id,
        status: payload.status,
      })
    )
  }

  /*
    Set a boolean so we know to fetch the latest results when we open the full mouth again.
    We need to fetch the latest results when we made changes to a specific full-mouth image.
  */
  if (
    object_type === "radiograph" &&
    isDashboard(window.location) &&
    activeFullMouthImage === payload.result_id &&
    currentFmxSet
  ) {
    yield put(setShouldFetchLatestFullMouth(true))
  }

  // After the last uploaded image is analyzed, we need to refresh the patient file to get the image metadata.
  const currentBatchUpload: ICurrentBatchUpload = yield select(
    getCurrentBatchUpload
  )
  const activePatientImages: ActivePatientImages[] | null = yield select(
    getActivePatientResultImages
  )
  const uploadedImages = currentBatchUpload.success.concat(
    currentBatchUpload.error
  )

  const allImagesAreAnalyzed =
    !!uploadedImages.length &&
    uploadedImages.every((id) =>
      activePatientImages?.some(
        (patientImage) =>
          patientImage.resultId === id && patientImage.status !== "pending"
      )
    )

  if (allImagesAreAnalyzed) {
    yield call(refreshPatientFile)
  }
}

export default function* entitiesSaga() {
  yield takeEvery(WebSocketTypes.Connect, connectSaga)
  yield takeLatest(UserTypes.GetUserName, connectUIEventsSaga)
  yield takeEvery(
    [
      FilterTypes.SetFilterStatus,
      AdjustmentTypes.ToggleAnnotationOnTooth,
      UserTypes.ToggleCariesPro,
      UserTypes.ToggleBonelossPro,
      FilterTypes.ToggleHsm,
      ServerDataTypes.ChangeUserAddition,
      ServerDataTypes.SetToothBoneloss,
      ServerDataTypes.SetMovedTeeth,
      ServerDataTypes.AdjustFilter,
      ServerDataTypes.FlipImage,
      ServerDataTypes.AddUserAdditions,
      ImageTypes.RotateImage,
      ImageTypes.ReanalyzeImage,
      ImageTypes.OpenPdfReport,
      ImageTypes.SaveReport,
      ImageTypes.CopyReportAsImage,
      ImageTypes.CopyReportAsText,
      ImageTypes.SetShownRadiographAnnotations,
      TeethTypes.SetActiveTooth,
    ],
    UIEventsSaga
  )
  yield takeEvery(
    WebSocketTypes.SetEventSourceMessage,
    setEventSourceMessageSaga
  )
}
