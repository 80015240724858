export enum FilterTypes {
  ToggleFilterStatus = "@@Filters/ToggleFilterStatus",
  SetFilterStatus = "@@Filters/SetFilterStatus",
  ToggleHsm = "@@Filters/ToggleHsm",
  SetHsm = "@@Filters/SetHsm",
  SetInitialState = "@@Filters/SetInitialState",
  SetIsEnabled = "@@Filters/SetIsEnabled",
}

export type IActiveNames = Readonly<{
  restorations: boolean
  apical: boolean
  caries: boolean
  calculus: boolean
  nervus: boolean
}>
