import { createAction } from "typesafe-actions"

import { XraysTypes } from "../types/xraysTypes"
import { CreateRadiographSet, UpdateSet } from "../types/patientTypes"

export const deleteXrayStart = createAction(
  XraysTypes.DeleteXrayStart
)<string>()

export const deleteMultipleRadiographs = createAction(
  XraysTypes.DeleteMultipleRadiographs
)<string[]>()

export const requestDeleteRadiograph = createAction(
  XraysTypes.RequestDeleteRadiograph
)<string>()

export const createRadiographSet = createAction(
  XraysTypes.CreateRadiographSet
)<CreateRadiographSet>()

export const updateRadiographSet = createAction(
  XraysTypes.UpdateRadiographSet
)<UpdateSet>()

export const removeRadiographSet = createAction(
  XraysTypes.RemoveRadiographSet
)<string>()
