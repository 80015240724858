import { InferenceStatus } from "./dataStructureTypes"

export enum WebSocketTypes {
  Connect = "@@Socket/Connect",
  UiEvents = "@@Socket/UiEvents",
  SetEventSourceMessage = "@@Socket/SetEventSourceMessage",
}

export type PatientEvent = {
  object_type: "patient"
  event_type: "create" | "update" | "delete"
  patient_uuid: string
}

export type RadiographEvent = {
  object_type: "radiograph"
  event_type: "create" | "update" | "delete"
  patient_uuid: string | null
  result_id: string
  status: InferenceStatus
}

export type EventSourceData = PatientEvent | RadiographEvent
