export enum DrawingTypes {
  SetDrawingAction = "@@Drawing/SetDrawingAction",
  ToggleActiveDrawingMode = "@@Drawing/ToggleActiveDrawingMode",
  SetDrawingAnnotation = "@@Drawing/SetDrawingAnnotation",
  SetInitialState = "@@Drawing/SetInitialState",
  SetDrawingLineThickness = "@@Drawing/SetDrawingLineThickness",
  SetDrawing = "@@Drawing/SetDrawing",
  DeleteDrawingCanvas = "@@Drawing/DeleteDrawingCanvas",
  SetShowDrawingWarning = "@@Drawing/SetShowDrawingWarning",
  SetActivePeriDrawing = "@@Drawing/SetActivePeriDrawing",
  SetActiveDrawingMask = "@@Drawing/SetActiveDrawingMask",
  SaveDrawingAnnotation = "@@Drawing/SaveDrawingAnnotation",
  SetActiveAddition = "@@Drawing/SetActiveAddition",
  SetIsErasing = "@@Drawing/SetIsErasing",
  SetActiveCariesProId = "@@Drawing/SetActiveCariesProId",
  SetActivePeriId = "@@Drawing/SetActivePeriId",
  SetShowDrawAnnotationButton = "@@Drawing/SetShowDrawAnnotationButton",
}

// DrawingAction enum key-value pairs to be camelCase as it is used with state objects
export enum DrawingAction {
  select = "select",
  annotate = "annotate",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
}

export enum DrawingLineThickness {
  Thin = 3,
  Medium = 6,
  Thick = 9,
}

// DrawingAnnotation enum key-value pairs to be camelCase as it is used with state objects
export enum DrawingAnnotation {
  none = "",
  caries = "caries",
  apical = "apical",
  calculus = "calculus",
  bridges = "bridges",
  crowns = "crowns",
  fillings = "fillings",
}
