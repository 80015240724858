import { createAction } from "typesafe-actions"

import { ModalTypes, ModalType, TranslationInput } from "../types/modalTypes"

export const openModal = createAction(ModalTypes.OpenModal)<ModalType | null>()
export const closeModal = createAction(ModalTypes.CloseModal)<string | void>()
export const setNewAIVersionModalShownIds = createAction(
  ModalTypes.SetNewAiVersionModalShownIds
)<string>()

export const setSnapshotWarning = createAction(
  ModalTypes.SetSnapshotWarning
)<TranslationInput | null>()
