import React, { useEffect, useState } from "react"
import { Notification } from "@align-cpf/uikit"
import useStyles from "./FullScreenNotification.styles"
import { useTranslation } from "react-i18next"
import { PRODUCT_NAME } from "library/utilities/constants"
import { useSelector } from "react-redux"
import { getIsIteroUser } from "library/common/selectors/user"

const FullScreenNotification: React.FC<{}> = () => {
  const [showNotification, setShowNotification] = useState(false)
  const [isLeavingNotification, setIsLeavingNotification] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    const messageHandler = (msgEvent: MessageEvent) => {
      if (msgEvent?.data !== "BACK_TO_LANDING") return
      setShowNotification(true)
      setIsLeavingNotification(true)
    }

    window.addEventListener("message", messageHandler)
    return () => window.removeEventListener("message", messageHandler)
  }, [])

  const isIteroUser = useSelector(getIsIteroUser)
  useEffect(() => {
    if (!isIteroUser) return
    setShowNotification(true)
    setTimeout(() => {
      setShowNotification(false)
    }, 2000)
  }, [isIteroUser])

  if (!showNotification) return null

  return (
    <div
      className={classes.notificationContainer}
      data-testid="fullscreen-notification"
    >
      <div className={classes.notificationWrapper}>
        <Notification
          delay={0}
          type="warning"
          message={t(
            `login.${isLeavingNotification ? "leaving" : "entering"}_message`,
            {
              productName: PRODUCT_NAME,
            }
          )}
        />
      </div>
    </div>
  )
}

export default FullScreenNotification
