import localStorage from "library/utilities/localStorage"
import { store } from "core/store/configureStore"
import { PatientMatch } from "library/common/types/patientTypes"
import { ParamContextValues } from "./UrlParamContext/UrlParamContext"

export const hideToken = (params: URLSearchParams) => {
  params.delete("token")
  window.history.replaceState(null, "", `?${params}`)
}

interface Location {
  pathname: string
}

const encodeTwice = (s: string) => encodeURIComponent(encodeURIComponent(s))

export const patientFileUrl = (patientUuid: string | null) =>
  patientUuid ? `/patients/${encodeTwice(patientUuid)}` : "/unassigned-patient/"

export const isDashboard = (location: Location) =>
  location.pathname.startsWith("/dashboard")

export const isOpenedRadiographSet = (location: Location) =>
  location.pathname.includes("/radiograph-set")

export const isAnalysisPage = (location: Location) =>
  isDashboard(location) || isOpenedRadiographSet(location)

export const isReportPage = (location: Location) =>
  location.pathname.includes("/report")

export const isPatientFile = (location: Location) =>
  location.pathname.includes("patients") ||
  location.pathname.includes("unassigned-patient")

export const defaultRoute = () => {
  const isIteroUser = !!store.getState().user.companyId
  const featureUploadFromPatientFile =
    store.getState().features.featureUploadFromPatientFile
  return (
    sessionStorage.getItem("last_url") ||
    (localStorage.getItem("installer_by_default") ||
    isIteroUser ||
    featureUploadFromPatientFile
      ? "/patients"
      : "/upload")
  )
}

export const openFullExperience = (
  patientMatch: PatientMatch | null,
  { companyIdParam, doctorIdParam }: ParamContextValues
) => {
  const contextParams = new URLSearchParams()
  companyIdParam && contextParams.append("companyId", companyIdParam)
  doctorIdParam && contextParams.append("doctorId", doctorIdParam)
  patientMatch && contextParams.append("patientId", patientMatch.externalId)

  // start from the login page to redirect to patient matching mode if needed
  // otherwise, continue from the current page
  const path = patientMatch ? "/login" : window.location.pathname

  open(`${window.location.origin}${path}?${contextParams}`, "_blank")
}
