import {
  RENEW_SUBSCRIPTION_URL,
  RENEW_SUBSCRIPTION_URL_AUTH0,
} from "./constants"
import { baseURL } from "library/utilities/fetch"
import fetch from "./fetch"
import { v4 as uuidv4 } from "uuid"

export const setBearerToken = (token: string | null) => {
  fetch.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const hasBearerToken = () =>
  !!fetch.defaults.headers.common.Authorization

export const saveOauthResponse = (response?: {
  refresh_token?: string
  id_token?: string
}) => {
  if (!response) return
  const refreshToken = response.refresh_token
  refreshToken && sessionStorage.setItem("refresh_token", refreshToken)
  const idToken = response.id_token
  idToken && sessionStorage.setItem("id_token", idToken)
}

export const clearSsoProvider = () => {
  sessionStorage.removeItem("sso_provider_type")
}

export const setSsoProviderType = (provider: SsoProviderType) =>
  sessionStorage.setItem("sso_provider_type", provider)
export const getSsoProviderType = () =>
  sessionStorage.getItem("sso_provider_type") as SsoProviderType

export const isAuth0 = () => getSsoProviderType() !== SsoProviderType.Itero

export const getProfileUrl = () =>
  isAuth0() && RENEW_SUBSCRIPTION_URL_AUTH0
    ? `${RENEW_SUBSCRIPTION_URL_AUTH0}/profile/profile`
    : null

export const getRenewUrl = () =>
  isAuth0() ? RENEW_SUBSCRIPTION_URL_AUTH0 : RENEW_SUBSCRIPTION_URL

export enum SsoProviderType {
  Wso2 = "align-db",
  Itero = "itero-db",
  Auth0 = "axi-db",
}

export const findProviderType = (isAuth0: boolean) =>
  getSsoProviderType() ||
  (isAuth0 ? SsoProviderType.Auth0 : SsoProviderType.Wso2)

export const redirectToSsoLogin = (providerType: SsoProviderType) => {
  setSsoProviderType(providerType)
  const state = uuidv4()
  sessionStorage.setItem("oauth_state", state)
  const params = new URLSearchParams({
    state,
    redirect_base: window.location.origin,
    response_type: "code",
  })
  window.location.href = `${baseURL}/authorize-redirect/${providerType}/?${params}`
}

export const redirectToSsoLogout = () => {
  const providerType = getSsoProviderType()
  if (!providerType) return

  const id_token_hint = sessionStorage.getItem("id_token")
  sessionStorage.removeItem("id_token")

  const params = new URLSearchParams({ redirect_base: window.location.origin })
  id_token_hint && params.set("id_token_hint", id_token_hint)
  window.location.href = `${baseURL}/logout-redirect/${providerType}/?${params}`
}
