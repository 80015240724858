export enum UserTypes {
  LogoutAction = "@@User/LogoutAction",
  LoginAction = "@@User/LoginAction",
  LoginActionWithData = "@@User/LoginActionWithData",
  LoginActionSuccess = "@@User/LoginActionSuccess",
  LoginActionError = "@@User/LoginActionError",
  ResetPasswordAction = "@@User/ResetPasswordAction",
  NewPasswordErrorAction = "@@User/NewPasswordErrorAction",
  OldPasswordErrorAction = "@@User/OldPasswordErrorAction",
  SetMustResetPasswordAction = "@@User/SetMustResetPasswordAction",
  ResetPasswordFirstTimeAction = "@@User/ResetPasswordFirstTimeAction",
  GetUserName = "@@User/GetUserName",
  SetUserResultStatus = "@@User/SetUserResultStatus",
  SetUserInfo = "@@User/SetUserInfo",
  SetServerError = "@@User/SetServerError",
  SetServerErrorMessage = "@@User/SetServerErrorMessage",
  ResetErrorAction = "@@User/ResetErrorAction",
  SetHandlerName = "@@User/SetHandlerName",
  SetHandlerHash = "@@User/SetHandlerHash",
  SubmitHandlerHash = "@@User/SubmitHandlerHash",
  ToggleCariesPro = "@@User/ToggleCariesPro",
  ToggleBonelossPro = "@@User/ToggleBonelossPro",
  SetBonelossPro = "@@User/SetBonelossPro",
  SetWhatsNew = "@@User/SetWhatsNew",
  SetLastCoachMarks = "@@User/SetLastCoachMarks",
  ToggleDrawingMode = "@@User/ToggleDrawingMode",
  GenerateToken = "@@User/GenerateToken",
  SetGeneratedToken = "@@User/SetGeneratedToken",
  SetImpersonate = "@@User/SetImpersonate",
  SetAlfaDocsApiKey = "@@User/SetAlfaDocsApiKey",
  SetUserIntegrationResultStatus = "@@User/SetUserIntegrationResultStatus",
  SetResetPasswordStatus = "@@User/SetResetPasswordStatus",
  SetIsOpenUserSettingsModal = "@@User/SetIsOpenUserSettingsModal",
  SetShowSmallScreenNotification = "@@User/SetShowSmallScreenNotification",
  SetIsPendoRunning = "@@User/SetIsPendoRunning",
  SetHasDemoParam = "@@User/SetHasDemoParam",
}

// License enum values to be camelCase as it is used with state objects
export enum License {
  Trial = "trial",
  Invalid = "invalid",
  Valid = "valid",
}

// NumberingSystem enum values to be camelCase as it is used with state objects
export enum NumberingSystem {
  Fdi = "FDI",
  Universal = "universal",
}

export enum Role {
  None = "UNSET",
  InternalTest = "INTERNAL_TEST",
  ExternalTest = "EXTERNAL_TEST",
  ClinicalTrial = "CLINICAL_TRIAL",
  Demo = "DEMO",
}

export interface ContextQuery {
  companyId?: string
  doctorId?: string
  source?: string
  impersonate?: string
}

export enum LogoutReason {
  None = "None",
  Timeout = "Timeout",
  Button = "Button",
  Unauthorized = "Unauthorized",
}
