import { createAction } from "typesafe-actions"

import {
  AdjustmentTypes,
  AnnotationOnTooth,
  ShiftingSelection,
  PeriAnnotation,
  MoveAnnotation,
} from "library/common/types/adjustmentTypes"

export const adjustAnnotations = createAction(
  AdjustmentTypes.AdjustAnnotations
)<any>()
export const adjustAnnotationsSuccess = createAction(
  AdjustmentTypes.AdjustAnnotationsSuccess
)<any[]>()
export const moveAnnotation = createAction(
  AdjustmentTypes.MoveAnnotation
)<string>()
export const moveAnnotationSuccess = createAction(
  AdjustmentTypes.MoveAnnotationSuccess
)<any>()
export const moveAnnotationTo = createAction(
  AdjustmentTypes.MoveAnnotationTo
)<number>()
export const moveAnnotationToSuccess = createAction(
  AdjustmentTypes.MoveAnnotationToSuccess
)<MoveAnnotation>()
export const toggleAnnotationOnTooth = createAction(
  AdjustmentTypes.ToggleAnnotationOnTooth
)<{
  annotation: AnnotationOnTooth
  isChecked: boolean
}>()
export const togglePeriAnnotation = createAction(
  AdjustmentTypes.TogglePeriAnnotation
)<PeriAnnotation>()
export const toggleAnnotationSuccess = createAction(
  AdjustmentTypes.ToggleAnnotationSuccess
)<any>()
export const startExpanding = createAction(
  AdjustmentTypes.StartExpanding
)<number>()
export const startExpandingSuccess = createAction(
  AdjustmentTypes.StartExpandingSuccess
)<number>()
export const expandShiftingTo = createAction(
  AdjustmentTypes.ExpandShiftingTo
)<ShiftingSelection>()
export const expandShiftingToSuccess = createAction(
  AdjustmentTypes.ExpandShiftingToSuccess
)<number[]>()
export const moveShiftingStackTo = createAction(
  AdjustmentTypes.MoveShiftingStackTo
)<number>()
export const setNextActiveStack = createAction(
  AdjustmentTypes.SetNextActiveStack
)<number>()
export const setNextActiveStackSuccess = createAction(
  AdjustmentTypes.SetNextActiveStackSuccess
)<any>()
export const setInitialState = createAction(AdjustmentTypes.SetInitialState)()
export const toggleTeethAreShifting = createAction(
  AdjustmentTypes.ToggleTeethAreShifting
)()
