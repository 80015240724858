import { getParser } from "bowser"

export const browser = getParser(window.navigator.userAgent)

const minVersions = {
  chrome: ">=70",
  firefox: ">=60",
  safari: ">=9",
  edge: ">=79",
  electron: ">=18",
  chromium: ">=70",
}

export const getMinVersion = (browserName?: string) =>
  minVersions[browserName?.toLowerCase() as keyof typeof minVersions]

export const isBrowserValid =
  browser.satisfies(minVersions) ||
  (browser.getBrowser().name === "Chromium" &&
    browser.getPlatform().type === "desktop")

export const isDesktop = browser.getPlatform().type === "desktop"
export const isTouchDevice =
  "ontouchstart" in window || navigator.maxTouchPoints > 0 // Careful: This includes laptops with touchscreens
