import { store } from "core/store/configureStore"
import { formatISO9075, isToday as today } from "date-fns"
import parse from "date-fns/parse"

export function getFormatedDate(date?: Date) {
  const d = date || new Date()
  const curr_date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
  const curr_month =
    d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
  const curr_year = d.getFullYear()

  return `${curr_year}-${curr_month}-${curr_date}`
}

export const calculatedAge = (birthDate?: Date) => {
  if (!birthDate) return "-"
  const today = new Date()
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return !age || age < 0 ? "-" : age
}

export const isToday = (date: string | Date | undefined) => {
  const currentDate = parseDate(date)
  if (!date || !currentDate) return false

  return today(currentDate)
}

// We use ISO 8601 date formats throughout the application

export const parseDate = (date?: string | number | Date | null) => {
  if (!date) return
  const result = new Date(date)
  return isNaN(result.getTime()) ? undefined : result
}

export const transformDateAndTime = (date?: string | number | Date) => {
  const parsed = parseDate(date)
  if (!parsed) return
  return formatISO9075(parsed)
}

export const transformDate = (
  date?: string | number | Date,
  shouldShowTodayText?: boolean,
  t?: (value: string) => string
) => {
  const featureImagesPerSession =
    store.getState().features.featureImagesPerSession
  const parsed = parseDate(date)
  if (!parsed) return

  if (featureImagesPerSession && t && today(parsed)) {
    return shouldShowTodayText && today(parsed)
      ? t("app.image_navigator_overlay.today")
      : t("app.image_navigator_overlay.today") + ` ${getTimeAmPmFormat(parsed)}`
  } else {
    return formatISO9075(parsed, { representation: "date" })
  }
}

export const transformTime = (date?: string | number | Date) => {
  const parsed = parseDate(date)
  if (!parsed) return
  return formatISO9075(parsed, {
    representation: "time",
  }).substring(0, 5)
}

export const parseDateWithFormat = (val: string | null, dateFormat: string) => {
  if (val === null) return val
  const date = parse(val, dateFormat, new Date())
  return date.toJSON() ? date : null
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getTimeAmPmFormat = (date: Date) => {
  const parsedDate = parseDate(date)
  if (!parsedDate) return
  let hours = parsedDate.getHours()
  const minutes: number | string = parsedDate.getMinutes()
  const amPm = hours >= 12 ? "p.m." : "a.m."
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const hoursStr = hours < 10 ? "0" + hours.toString() : hours.toString()
  // appending zero in the start if hours less than 10
  const minutesStr =
    minutes < 10 ? "0" + minutes.toString() : minutes.toString()
  return hoursStr + ":" + minutesStr + " " + amPm
}
