import { CurrentFmx } from "library/common/types/fullMouthTypes"
import {
  ActivePatientImages,
  FmxTemplate,
  PatientMatch,
  RadiographSet,
} from "library/common/types/patientTypes"
import { IMeta } from "library/common/types/serverDataTypes"

export const updatedRadiographSetPositions = (
  activeRadiographSet: RadiographSet | CurrentFmx | null
) => {
  const radiographs = activeRadiographSet?.radiographs || []
  const changes = activeRadiographSet?.changes || []
  const positions = activeRadiographSet?.positions || []
  const positionsMap = new Map(positions.map((item) => [item.resultId, item]))
  const changesMap = new Map(changes.map((item) => [item.resultId, item]))

  const radiographPositions = radiographs.map((resultId, i) => {
    if (changesMap.has(resultId)) {
      return { position: changesMap.get(resultId)?.position || 0, resultId }
    }
    if (positionsMap.has(resultId) && !changesMap.has(resultId)) {
      return { position: positionsMap.get(resultId)?.position || 0, resultId }
    }
    return { position: i, resultId: resultId }
  })

  // We push all the changes which ids were not found in the positions array (newly added radiographs).
  changesMap.forEach((c) => {
    !positionsMap.has(c.resultId) && radiographPositions.push(c)
  })

  // Return a unique array of radiograph positions
  return [...new Map(radiographPositions.map((r) => [r.resultId, r])).values()]
}

/*
  This function gets the AI positions from the backend and transforms them into an array of strings.
  The new array is constructed by checking the template amount (fmx-18, fmx-14, etc.) and filling the corresponding AI positions.
  If the AI positions are blank, the array will be filled with empty strings.
*/
export const updatedRadiographs = (
  activeRadiographSet: RadiographSet | null
) => {
  const positions = activeRadiographSet?.positions?.map((p) => p.position) // Get the positions that were determined by the AI
  const totalPositions = Array.from(
    Array(templateAmount(activeRadiographSet?.template || FmxTemplate.Fmx18))
  )

  // We need to consider the positions that were determined by the AI.
  return totalPositions.map((_, i) =>
    positions?.includes(i)
      ? activeRadiographSet?.positions?.find((a) => a.position === i)
          ?.resultId || ""
      : ""
  )
}

// Get the number value from the template (fmx-14 -> 14)
export const templateAmount = (template: FmxTemplate) =>
  Number(template.split("-")[1] || 0)

export const isRadiographInUpperJaw = (position: number, template: number) =>
  position === 0 || (position ? position <= template / 2 - 1 : false)

export const extractedImageData = (
  id: string | null,
  items: { resultId: string; imageMetadata: IMeta | undefined }[]
) => {
  return items.find((i) => i.resultId === id) || null
}

export const extractedActivePatientImageData = (
  id: string | null,
  items: ActivePatientImages[]
) => {
  return items.find((i) => i.resultId === id) || null
}

export const fmxTeethNumbers: { [key: number]: string[] } = {
  0: ["16 - 14"],
  1: ["16 - 13"],
  2: ["13 - 11"],
  3: ["11 - 22"],
  4: ["22 - 24"],
  5: ["23 - 26"],
  6: ["24 - 27"],
  7: ["47 - 46"],
  8: ["47 - 44"],
  9: ["43 - 41"],
  10: ["42 - 32"],
  11: ["31 - 34"],
  12: ["35 - 37"],
  13: ["36 - 37"],
  14: ["18 - 14", "48 - 44"],
  15: ["17 - 13", "47 - 43"],
  16: ["24 - 27", "34 - 37"],
  17: ["25 - 28", "35 - 38"],
}

export const formatIteroPatientInfo = (patient?: PatientMatch | null) => {
  if (!patient) return ""
  return Object.entries(patient)
    .filter(([p, v]) => p !== "externalId" && p !== "link" && v)
    .map(([, v]) => v)
    .join(", ")
}
