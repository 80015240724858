import { Modals } from "../reducers/modalsReducer"

export enum ModalTypes {
  OpenModal = "@@Modal/OpenModal",
  CloseModal = "@@Modal/CloseModal",
  SetNewAiVersionModalShownIds = "@@Modal/SetNewAiVersionModalShownIds",
  SetSnapshotWarning = "@@Modal/SetSnapshotWarning",
}

/*
  Rearrange to change the help modal button order.
  HelpModalEntry enum values to be camelCase as it is used with translations
*/
export enum HelpModalEntry {
  Legend = "legend",
  Analysis = "analysis",
  Navigation = "navigation",
  WhatsNew = "new",
  Languages = "language",
  UserManual = "manual",
  ProductInformation = "info",
  Invisalign = "invisalign",
  Chat = "chat",
  ReportProblem = "problem",
}

export type TranslationInput = {
  key: string
  options?: Record<string, string>
}

export type ModalType = {
  openedModal: Modals
  currentFaqTab?: HelpModalEntry
  navigatingUrl?: string
}
