import { put, takeLatest, select, takeEvery } from "redux-saga/effects"

import { AdjustmentTypes } from "library/common/types/adjustmentTypes"
import { ImageControlsTypes } from "library/common/types/imageControlsTypes"
import { FilterTypes } from "library/common/types/filterTypes"
import { UserTypes } from "library/common/types/userTypes"
import { ServerDataTypes } from "library/common/types/serverDataTypes"

import { ActiveFilter } from "library/common/reducers/imageControlsReducer"

import * as imageControlActions from "library/common/actions/imageControls"
import * as withHistoryActions from "library/common/actions/withHistory"
import * as teethActions from "library/common/actions/teeth"
import * as adjustmentActions from "library/common/actions/adjustments"
import * as serverDataActions from "library/common/actions/serverData"

import * as imageControlSelectors from "library/common/selectors/imageControls"
import * as serverDataSelectors from "library/common/selectors/serverData"
import * as adjustmentSelectors from "library/common/selectors/adjustments"

import { flipTooth } from "@dentalxrai/transform-landmark-to-svg"

function* setActiveFilterSaga({
  payload: nextActiveFilter,
}: {
  type: ImageControlsTypes.SetActiveFilter
  payload: ActiveFilter
}) {
  const activeFilter: string = yield select(
    imageControlSelectors.getActiveFilter
  )
  if (nextActiveFilter === activeFilter) {
    yield put(
      imageControlActions.setActiveFilterSuccess(ActiveFilter.Navigation)
    )

    return
  }
  yield put(imageControlActions.setActiveFilterSuccess(nextActiveFilter))
}

function* disableFullscreen() {
  const isFullscreen: boolean = yield select(
    imageControlSelectors.getIsFullscreen
  )
  if (isFullscreen) {
    yield put(imageControlActions.toggleFullscreen())
  }
}

function* flipImageSaga() {
  const activeTooth: number | null = yield select(
    serverDataSelectors.getActiveTooth
  )
  const teethAreShifting: boolean = yield select(
    adjustmentSelectors.getTeethAreShifting
  )
  const shiftingTeeth: number[] = yield select(
    adjustmentSelectors.getShiftingTeeth
  )

  yield put(withHistoryActions.rememberState())
  yield put(serverDataActions.toggleFlipImage())

  // when a tooth is selected and we flip the image, the selected tooth stays in the wrong side
  if (activeTooth) {
    yield put(teethActions.setActiveTooth(flipTooth(activeTooth)))
  }
  if (teethAreShifting) {
    // The highlight square that tells the user which teeth are being moved currently takes as a reference
    //   the tooth found in `shiftingTeeth` and with the length of `shiftingTeeth` calculates how many tooth spaces
    //   will cover the highlighted square from left to right, so in order to flip the image correctly we must reverse
    //   the order of the list in `shiftingTeeth`
    const shiftedTeethFlipped = shiftingTeeth
      .slice(0)
      .reverse()
      .map((tooth: number) => flipTooth(tooth))
    yield put(adjustmentActions.expandShiftingToSuccess(shiftedTeethFlipped))
  }
}

// Reverse shiftingTeeth array when clicking display flipped button
function* flipImageHorizontallySaga() {
  const teethAreShifting: boolean = yield select(
    adjustmentSelectors.getTeethAreShifting
  )
  const shiftingTeeth: number[] = yield select(
    adjustmentSelectors.getShiftingTeeth
  )

  if (teethAreShifting) {
    const shiftedTeethFlipped = [...shiftingTeeth].reverse()
    yield put(adjustmentActions.expandShiftingToSuccess(shiftedTeethFlipped))
  }
}

export default function* watchImageControls() {
  yield takeLatest(ImageControlsTypes.SetActiveFilter, setActiveFilterSaga)
  yield takeEvery(
    [
      UserTypes.ToggleCariesPro,
      FilterTypes.ToggleHsm,
      AdjustmentTypes.ToggleTeethAreShifting,
    ],
    disableFullscreen
  )
  yield takeEvery(ServerDataTypes.FlipImage, flipImageSaga)
  yield takeEvery(
    ServerDataTypes.ToggleDisplayHorizontallyFlipped,
    flipImageHorizontallySaga
  )
}
