import { call, select, takeLatest, put, takeEvery } from "redux-saga/effects"

import { history } from "core/store/configureStore"
import {
  ICurrentBatchUpload,
  UploadTypes,
} from "library/common/types/uploadTypes"
import {
  IReportProblem,
  setReportProblemId,
  ActionWithData,
  uploadActionPreloaderImage,
  uploadActionSuccess,
  uploadActionError,
  uploadActionSetImageSizeError,
  setConflictedFiles,
  clearUploadFiles,
  setSuccessBatchUpload,
  setErrorBatchUpload,
} from "../actions/upload"
import { setUserInfo } from "../actions/user"
import {
  requestSendReportProblem,
  requestUploadImage,
} from "library/services/uploadApi"
import { AxiosResponse } from "axios"
import {
  setImageIDBreadcrumb,
  setPatientFileBreadcrumb,
} from "../actions/breadcrumbs"
import { getContextQueryParams } from "../selectors/user"
import { ContextQuery } from "../types/userTypes"
import { closeModal, openModal } from "../actions/modal"
import { Modals } from "../reducers/modalsReducer"
import { getOpenedModal } from "../selectors/modals"
import { getFeatureUploadFromPatientFile } from "../selectors/features"
import { getImageIDBreadcrumb } from "../selectors/breadcrumbs"
import { getCurrentBatchUpload } from "../selectors/upload"
import { setOpenToast } from "../actions/toast"
import { ToastType } from "../types/toast"
import i18next from "i18next"
import { getActivePatientResult } from "../selectors/patient"
import { ActivePatientResult, Status } from "../types/patientTypes"
import { IMeta } from "../types/serverDataTypes"
import {
  addActivePatientImage,
  setActivePatientListItem,
} from "../actions/patient"

export function* uploadImage(uploadData: {
  payload: ActionWithData
  type: string
}) {
  const openedModal: Modals | null = yield select(getOpenedModal)
  const imageIDBreadcrumb: string = yield select(getImageIDBreadcrumb)
  try {
    yield put(uploadActionPreloaderImage())
    const image = uploadData.payload.data
    const params: ContextQuery = yield select(getContextQueryParams)
    const featureUploadFromPatientFile: boolean = yield select(
      getFeatureUploadFromPatientFile
    )

    const data: AxiosResponse<{
      id: string
      meta: IMeta
      uploadsRemaining?: number
    }> = yield call(
      requestUploadImage,
      {
        meta: JSON.stringify(uploadData.payload.meta),
        data: image,
      },
      params
    )
    const { id, uploadsRemaining } = data.data
    // update how many image uploads remain for this user
    yield put(setUserInfo({ uploadsRemaining }))
    yield put(uploadActionSuccess())
    if (openedModal === Modals.UploadImage) {
      yield put(closeModal())
    }
    // clear PatientFileBreadcrumb of previous image. The new image breadcrumbs are set when updating the image.
    yield put(setPatientFileBreadcrumb(""))
    if (featureUploadFromPatientFile) {
      const currentBatchUpload: ICurrentBatchUpload = yield select(
        getCurrentBatchUpload
      )
      const totalLength = currentBatchUpload.total || 0
      const successLength = currentBatchUpload.success?.length || 0
      const errorLength = currentBatchUpload.error?.length || 0

      const isBatchUploadComplete =
        totalLength === successLength + 1 + errorLength

      const successToastMessage = (successLength: number) => {
        return i18next.t("app.toast.image_upload_success", {
          count: successLength === 0 ? errorLength + 1 : successLength,
        })
      }

      // Add image to state to display in the UI
      yield put(
        addActivePatientImage({
          resultId: id,
          imageMetadata: data.data.meta,
          imageDate: uploadData.payload.meta.imageDate,
          statusDisplayTag: Status.New,
          report_submitted: "",
          viewed: false,
          generalComment: "",
          addedComments: [],
          status: "pending",
        })
      )

      if (imageIDBreadcrumb) {
        yield put(setImageIDBreadcrumb(""))
      }

      const activePatientResult: ActivePatientResult = yield select(
        getActivePatientResult
      )

      const { patientID, patientName, dateOfBirth } =
        activePatientResult.patient || {}

      const {
        srcMetadata: {
          patientID: imagePatientID,
          patientName: imagePatientName,
          dateOfBirth: imageDateOfBirth,
        },
      } = data.data.meta

      const imageHasPatientInfo =
        imagePatientID || imagePatientName || imageDateOfBirth

      if (imageHasPatientInfo) {
        const imagePatientMeta = {
          patientID: imagePatientID || "",
          patientName: imagePatientName || "",
          dateOfBirth: imageDateOfBirth || "",
        }
        yield put(openModal({ openedModal: Modals.UploadConflict }))
        yield put(
          setConflictedFiles({
            currentPatientInfo: {
              patientID: patientID || "",
              patientName: patientName || "",
              dateOfBirth: dateOfBirth || "",
            },
            imagePatientInfo: imagePatientMeta,
            dateOfRadiograph: uploadData.payload.meta.imageDate,
            fileName: uploadData.payload.meta.fileName,
            id: id,
          })
        )
      }
      yield put(setSuccessBatchUpload(id))

      if (!isBatchUploadComplete) {
        yield put(
          setOpenToast({
            type: ToastType.ImageUpload,
            message: i18next.t("app.toast.image_upload_pending", {
              currentProgress: successLength || 0 + 1,
              total: totalLength,
            }),
            notificationType: "information",
            delay: 0,
          })
        )
      } else {
        yield put(
          setOpenToast({
            type: ToastType.ImageUpload,
            message: successToastMessage(successLength + 1),
            notificationType: "success",
          })
        )
        yield put(
          setActivePatientListItem({
            id,
            isRadiographSet: false,
          })
        )
        yield put(clearUploadFiles())
      }
    } else {
      yield history.push(`/dashboard/${id}`)
    }
  } catch (error: any) {
    yield put(setErrorBatchUpload(uploadData.payload.meta.fileName))
    yield put(uploadActionError())
    if (error.response?.status === 400 && error.response.data) {
      const res = error.response.data
      let sizeError = ""
      if (/is too small/.test(res.imageHeight)) {
        sizeError = "small_height"
      } else if (/is too small/.test(res.imageWidth)) {
        sizeError = "small_width"
      } else if (/rotated incorrectly/.test(res.imageWidth)) {
        sizeError = "aspect_ratio"
      } else if (/is too large/.test(res.imageHeight)) {
        sizeError = "large_height"
      } else if (/is too large/.test(res.imageWidth)) {
        sizeError = "large_width"
      } else if (res.image) {
        sizeError = "general_read_error"
      }
      if (sizeError) {
        yield put(
          setOpenToast({
            type: ToastType.ImageUploadError,
            message: i18next.t(`upload.modal.title_${sizeError}`),
            notificationType: "error",
          })
        )
        yield put(uploadActionSetImageSizeError(sizeError))
        return
      }
    }

    // unknown error: we cannot handle it
    throw error
  }
}

export function* sendReportProblem(problem: {
  payload: IReportProblem
  type: string
}) {
  try {
    const { data } = yield call(requestSendReportProblem, problem.payload)
    yield put(setReportProblemId(data.id))
  } catch (error) {
    console.log(error)
  }
}

export default function* watchUpload() {
  yield takeEvery(UploadTypes.UploadActionWithData, uploadImage)
  yield takeLatest(UploadTypes.UploadActionSendReportProblem, sendReportProblem)
}
