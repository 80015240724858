import {
  Boneloss,
  Detection,
  HistoricalResult,
  NerveCanal,
  ToothSegment,
  ICroppedTeeth,
} from "./dataStructureTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"

export enum EntitiesTypes {
  SaveDetections = "@@Entities/SaveDetections",
  SetInitialState = "@@Entities/SetInitialState",
  SetImageId = "@@Entities/SetImageId",
  SetViewed = "@@Entities/SetViewed",
  AddDetectedTeeth = "@@Entities/AddDetectedTeeth",
  DeleteDetectedTooth = "@@Entities/DeleteDetectedTooth",
}

export type Entities = Readonly<{
  apical: Detection[]
  boneloss?: Boneloss
  caries: Detection[]
  restorations: Detection[]
  detectedTeeth: Tooth[]
  imageId: string
  calculus: Detection[]
  nervus: Detection[]
  segments: ToothSegment[]
  impacted: Detection[]
  historicalResults: HistoricalResult[]
  nerveCanal: NerveCanal[]
  viewed: boolean
  croppedTeeth?: ICroppedTeeth
}>
