import { createAction } from "typesafe-actions"
import { AnnotationOnTooth } from "../types/adjustmentTypes"

import {
  DrawingTypes,
  DrawingAction,
  DrawingLineThickness,
  DrawingAnnotation,
} from "../types/drawing"

export const setInitialState = createAction(DrawingTypes.SetInitialState)()

export const setDrawingAction = createAction(
  DrawingTypes.SetDrawingAction
)<DrawingAction>()

export const toggleActiveDrawingMode = createAction(
  DrawingTypes.ToggleActiveDrawingMode
)()

export const setDrawingAnnotation = createAction(
  DrawingTypes.SetDrawingAnnotation
)<DrawingAnnotation>()

export const setDrawing = createAction(DrawingTypes.SetDrawing)<boolean>()

export const setShowDrawingWarning = createAction(
  DrawingTypes.SetShowDrawingWarning
)<boolean>()

export const setDrawingLineThickness = createAction(
  DrawingTypes.SetDrawingLineThickness
)<DrawingLineThickness>()

export const saveDrawingAnnotation = createAction(
  DrawingTypes.SaveDrawingAnnotation
)()

export const setActiveAddition = createAction(
  DrawingTypes.SetActiveAddition
)<AnnotationOnTooth | null>()

export const setIsErasing = createAction(DrawingTypes.SetIsErasing)<boolean>()

export const setActiveCariesProId = createAction(
  DrawingTypes.SetActiveCariesProId
)<number | null>()

export const setActivePeriId = createAction(DrawingTypes.SetActivePeriId)<
  number | null
>()
export const setShowDrawAnnotationButton = createAction(
  DrawingTypes.SetShowDrawAnnotationButton
)<boolean>()
