import {
  AnnotationName,
  BonelossChange,
  CariesDepth,
  RestorationSubtype,
} from "./adjustmentTypes"

export enum ServerDataTypes {
  AddUserChanges = "@@ServerData/AddUserChanges",
  DeleteUserChange = "@@ServerData/DeleteUserChange",
  EditUserChange = "@@ServerData/EditUserChange",
  AddUserAdditions = "@@ServerData/AddUserAdditions",
  AddCariesAdditions = "@@ServerData/AddCariesAdditions",
  ChangeUserAddition = "@@ServerData/ChangeUserAddition",
  DeleteUserAddition = "@@ServerData/DeleteUserAddition",
  DeleteUserAdditionById = "@@ServerData/DeleteUserAdditionById",
  DeleteUserAdditionByIdSuccess = "@@ServerData/DeleteUserAdditionByIdSuccess",
  UserAddAddedTeeth = "@@ServerData/UserAddAddedTeeth",
  UserDeleteAddedTeeth = "@@ServerData/UserDeleteAddedTeeth",
  UserAddDeletedTeeth = "@@ServerData/UserAddDeletedTeeth",
  UserDeleteDeletedTeeth = "@@ServerData/UserDeleteDeletedTeeth",
  SetMovedTeeth = "@@ServerData/SetMovedTeeth",
  DeleteMovedUserChange = "@@ServerData/DeleteMovedUserChange",
  SetInitialState = "@@ServerData/SetInitialState",
  SetInitialImageMetaState = "@@ServerData/SetInitialImageMetaState",
  SetGeneralComment = "@@ServerData/SetGeneralComment",
  AddAddedComments = "@@ServerData/AddAddedComments",
  AddNote = "@@ServerData/AddNote",
  SetNotes = "@@ServerData/SetNotes",
  SetTeethLoss = "@@ServerData/SetTeethLoss",
  SetAge = "@@ServerData/SetAge",
  SetComplexity = "@@ServerData/SetComplexity",
  SetDiabetes = "@@ServerData/SetDiabetes",
  SetSmoking = "@@ServerData/SetSmoking",
  SetBonelossCategory = "@@ServerData/SetBonelossCategory",
  SetBonelossIndex = "@@ServerData/SetBonelossIndex",
  SetToothBoneloss = "@@ServerData/SetToothBoneloss",
  SetExtendedOrDistribution = "@@ServerData/SetExtendedOrDistribution",
  ChangeAnnotation = "@@ServerData/ChangeAnnotation",
  ToggleAnnotation = "@@ServerData/ToggleAnnotation",
  FlipImage = "@@ServerData/FlipImage",
  ToggleFlipImage = "@@ServerData/ToggleFlipImage",
  SaveImageMeta = "@@ServerData/SaveImageMeta",
  SaveBonelossForm = "@@ServerData/SaveBonelossForm",
  MoveComments = "@@ServerData/MOVE_COMMENT",
  ToggleDisplayHorizontallyFlipped = "@@ServerData/ToggleDisplayHorizontallyFlipped",
  SetInferenceStatus = "@@ServerData/SetInferenceStatus",
  SetPatientUuid = "@@ServerData/SetPatientUuid",
  AdjustFilter = "@@ServerData/AdjustFilter",
}

export interface AnnotationToRender {
  toothName: number
  type: AnnotationName
  subtype?: RestorationSubtype
  shouldMakeActive: boolean // if the checkbox should react to this
  isAddition: boolean // if this is an addition by the user
  // HSM:
  isHSM: boolean // if this is an HSM annotation
  isAcceptedHSM: boolean
  isRejectedHSM: boolean
  isUnconfirmedHSM: boolean // whether HSM was neither `accepted` nor `rejected`
  isEnlargementHSM: boolean // is an unconfirmed HSM, but whether or not enlarges an existing blob/mask
  // it will depend on the logic needed at the moment to be a displayable to the user. Check function parseHsmData()
  isDisplayableHSM: boolean
  // Caries Pro:
  location?: string
  depth?: CariesDepth
  id?: number
  center_mass_x?: number // Only for PERI
  center_mass_y?: number // Only for PERI
  replacing?: number // HSM on PERI: replacing blob with id
  rejectedIds?: number[] // Drawings: rejected existing IDs
  mask?: string | undefined // Drawing mask
  // New nervus:
  nerveMask?: string
  toothwiseOutput?: {
    centerCoordinates: number[]
    radius: number
    toothName: number
  }[]
}

export type Comment = Readonly<{
  toothName: number
  comment: string
}>

export type INote = {
  text: string
  created: string
}

export type sortedINote = {
  date: string | undefined
  entries: INote[]
}

export interface BoneLossFormFull extends BoneLossFormUser {
  stage: string | undefined
  maxBoneLossPercent: number | undefined
  maxBoneLossPercentCategory: string | undefined
  boneLossIndex: string | undefined
  grade: string | undefined
  draft: boolean
  showSt6: boolean
  showComplex: boolean
}

export interface BoneLossFormUser {
  maxBoneLossPercentCategoryOverride: string | undefined
  boneLossIndexOverride: string | undefined
  toothLoss: string
  toothLossOverride: boolean
  complications: string
  distribution: string
  age: number
  diabetes: string
  smoking: string
  changedTeeth: BonelossChange[]
}

// for changing individual Annotations (such as Caries Pro)
export type AnnotationChange = Readonly<{
  id: number
  location?: string
  depth?: CariesDepth
}>

// TODO (martin): These types are only used in the detection list, use something better
export type IAnnotations = Readonly<{
  caries?: AnnotationToRender[]
  apical?: AnnotationToRender[]
  restorations?: AnnotationToRender[]
  calculus?: AnnotationToRender[]
  nervus?: AnnotationToRender[]
  impacted?: AnnotationToRender[]
}>

export type ITooth = Readonly<{
  tooth: number
  isDetected: boolean
  hasComment: boolean
  annotations: IAnnotations | null
}>

// Features enum values to be camelCase as it is used with API responses
export enum Features {
  ToothBasedPeri = "toothBasedPeri",
}

// WarningLabel enum values to be camelCase as it is used with state objects
export enum WarningLabel {
  NonPermanent = "nonPermanent",
  PartiallyShown = "partiallyShown",
  OngoingRct = "ongoingRct",
  OrthoCondition = "orthoCondition",
}
export type IWarnings = Partial<Record<WarningLabel, boolean>>

export interface IMeta {
  angleImageRotation: number | null
  patientID: string
  patientName: string
  dateOfBirth: string
  imageDate: string
  analysisDate: string
  fileName: string
  kind: Kind
  isImageHorizontallyFlipped: boolean
  displayHorizontallyFlipped: boolean
  imageWidth: number
  imageHeight: number
  isOwner: boolean
  features?: Features[]
  outdatedAnalysis: boolean
  imageFormatOrig: string
  thumbnailImageWidth: number | null
  thumbnailImageHeight: number | null
  missingMetadata?: boolean
  patientUuid: string | null
  autoRotated?: boolean
  autoRotationDismissed?: boolean
  orthoConditionDismissed?: boolean
  externalEvent?: IExternalEvent
  warnings?: IWarnings
  isImageDateUpdated: boolean
  srcMetadata: {
    dateOfBirth?: string
    imageDate: string
    patientName?: string
    patientID?: string
  }
}

export interface IExternalEvent {
  id: string
  time: string
  detail: {
    data: {
      archiveId: string
      documentId: string
      practiceId: string
    }
    metadata: {
      fileName: string
      imageDate: string
      patientID: string
      folderName: string
      dateOfBirth: string
      patientName: string
    }
  }
  region: string
  source: string
  account: string
  version: string
  detail_type: string
}

export interface IMoveComment {
  oldTeeth: number[]
  movedTeeth: number[]
}

export interface IInferenceStatus {
  status: string
  message?: string
}

// LoadingStatus enum values to be camelCase as it is used with state objects
export enum LoadingStatus {
  Processing = "processing",
  Completed = "completed",
  Warning = "warning",
  Error = "error",
}

export type AnnotationRecord = Partial<
  Record<AnnotationName, AnnotationToRender[]>
>

export type ActiveToothAnnotations = Readonly<{
  tooth: number
  annotations: AnnotationRecord
}>

export type MaxBoneLossInfo = Readonly<{
  maxBoneLossPercent: number
  toothName: number | null
}>

export type ImageFilters = Readonly<{
  contrast: number
  saturation: number
  brightness: number
}>

// ImageFilterTypes enum values to be camelCase as it is used with state objects
export enum ImageFilterTypes {
  Contrast = "contrast",
  Saturation = "saturation",
  Brightness = "brightness",
}

export enum ToothMapPosition {
  BelowXray,
  BesideXray,
  RightBar,
  None,
}

// Kind enum values to be camelCase as it is used with state objects
export enum Kind {
  Opg = "OPG",
  Bw = "BW",
  Peri = "PERI",
  Other = "OTHER",
  Unknown = "",
}

// CariesButtonType enum values to be camelCase as it is used with state objects
export enum CariesButtonType {
  Depth = "depth",
  Location = "location",
}

export type CariesButton = {
  type: CariesButtonType
  label: CariesDepth | string
}

export type ToothSegmentInfo = {
  imageData: Uint8ClampedArray
  toothName: number
}

// RegulatoryMarket enum key-value pairs to be camelCase as it is used with state objects
export enum RegulatoryMarket {
  fda = "fda",
  lmr = "lmr",
  cad = "cad",
  all = "all",
}
