export default class Storage {
  private static readonly prefix = "dentalXRai/"
  private static readonly dataAndPrivacyPrefix = "truste.eu.cookie."

  public static getItem(name: string) {
    return JSON.parse(localStorage.getItem(this.prefix + name) as any)
  }

  public static getDataAndPrivacyItem(name: string) {
    return JSON.parse(
      localStorage.getItem(this.dataAndPrivacyPrefix + name) as any
    )
  }

  public static setItem(
    name: string,
    value: string | boolean | Record<string, number | string | boolean>
  ) {
    localStorage.setItem(this.prefix + name, JSON.stringify(value))
  }

  public static removeItem(name: string) {
    localStorage.removeItem(this.prefix + name)
  }
}
