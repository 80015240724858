import { createAction } from "typesafe-actions"

import { FilterTypes } from "library/common/types/filterTypes"
import { FilterStatus, FiltersState } from "../reducers/filtersReducer"

export const toggleFilterStatus = createAction(
  FilterTypes.ToggleFilterStatus
)<FilterStatus>()
export const setFiltersStatus = createAction(
  FilterTypes.SetFilterStatus
)<FiltersState>()
export const toggleHSM = createAction(FilterTypes.ToggleHsm)()
export const setHSM = createAction(FilterTypes.SetHsm)<boolean>()
export const setInitialState = createAction(FilterTypes.SetInitialState)()
export const setIsEnabled = createAction(FilterTypes.SetIsEnabled)<boolean>()
