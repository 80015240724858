import { createAction } from "typesafe-actions"

import {
  EventSourceData,
  WebSocketTypes,
} from "library/common/types/webSocketTypes"

export const connect = createAction(WebSocketTypes.Connect)<string>()
export const UIEvents = createAction(WebSocketTypes.UiEvents)<unknown>()
export const setEventSourceMessage = createAction(
  WebSocketTypes.SetEventSourceMessage
)<EventSourceData>()
