import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects"
import { DismissalType, SavingType, SavingTypes } from "../types/savingTypes"
import {
  BoneLossFormFull,
  Comment,
  INote,
  ServerDataTypes,
} from "../types/serverDataTypes"
import { AnnotationOnTooth } from "../types/adjustmentTypes"
import { ResultStatus, UserChange } from "../types/dataStructureTypes"
import teethTypes from "library/common/types/teethTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { AdjustmentTypes } from "library/common/types/adjustmentTypes"
import { UserTypes } from "../types/userTypes"
import * as imageControlsSelectors from "library/common/selectors/imageControls"
import * as routeSelectors from "library/common/selectors/routes"
import * as savingDataActions from "library/common/actions/saving"
import { history } from "core/store/configureStore"

import {
  requestSendChanges,
  requestSendEvent,
  IRequestSendReport,
  IImageManipulation,
} from "library/services/uploadApi"

import {
  getAddedComments,
  getGeneralComment,
  getAllUserChanges,
  getAllRemovedTeeth,
  getAllAddedTeeth,
  getMovedTeeth,
  getAllAdditions,
  getImageMeta,
  getBoneLossFormValues,
  getIsImageHorizontallyFlipped,
  getIsOwner,
  getBrightnessSnapped,
  getContrastSnapped,
  getSaturationSnapped,
  getNotes,
} from "library/common/selectors/serverData"
import {
  getCariesPro,
  getOpenDateMs,
  getBonelossPro,
  getIsReportSentToAlfaDocs,
} from "../selectors/image"
import { resetOpenDataMsSaga } from "./imageSaga"
import { IMeta } from "../types/serverDataTypes"
import { notifyWebkitSave } from "library/utilities/integration"
import { setReportBreadcrumb } from "../actions/breadcrumbs"
import { closeModal, openModal } from "../actions/modal"
import { getOpenedModal } from "../selectors/modals"
import { Modals } from "../reducers/modalsReducer"
import { loadImage, setIsReportSentToAlfaDocs } from "../actions/image"
import { getImageId } from "../selectors/entities"
import { isDashboard } from "library/utilities/urls"
import { getContextQueryParams } from "../selectors/user"
import { ContextQuery } from "../types/userTypes"
import { getActivePatientListItem } from "../selectors/patient"
import { IActivePatientListItem } from "../types/patientTypes"
import { saveImageMeta } from "../actions/serverData"
import { HelpModalEntry } from "../types/modalTypes"
import { setShowDrawAnnotationButton } from "../actions/drawing"
import { setPatientImageMeta } from "../actions/patient"
import { getActiveFullMouthImage } from "../selectors/fullMouth"

function* requestSendChangesSaga({
  payload: savingData,
}: ReturnType<typeof savingDataActions.requestSendChanges>) {
  const generalComment: string = yield select(getGeneralComment)
  const addedComments: Comment[] = yield select(getAddedComments)

  const additions: AnnotationOnTooth[] = yield select(getAllAdditions)

  const changes: UserChange[] = yield select(getAllUserChanges)

  const removedTeeth: Tooth[] = yield select(getAllRemovedTeeth)
  const addedTeeth: Tooth[] = yield select(getAllAddedTeeth)
  const movedTeeth: Record<string, number> = yield select(getMovedTeeth)

  const resultId = savingData.resultId

  const cariesPro: boolean | undefined = yield select(getCariesPro)
  const bonelossPro: boolean | null = yield select(getBonelossPro)
  const openedModal: Modals | null = yield select(getOpenedModal)

  const isImageHorizontallyFlipped: boolean = yield select(
    getIsImageHorizontallyFlipped
  )

  const boneLoss: BoneLossFormFull = yield select(getBoneLossFormValues)

  const meta: IMeta = yield select(getImageMeta)
  const isOwner: boolean = yield select(getIsOwner)
  const notes: INote[] = yield select(getNotes)

  yield put(savingDataActions.setSavingResultStatus(ResultStatus.Loading))

  const metaData = {
    meta: {
      ...meta,
      isImageHorizontallyFlipped,
    },
  }
  const data: IRequestSendReport =
    savingData.savingChanges === SavingType.ImageMetaData
      ? {
          ...metaData,
          resultId,
        }
      : {
          ...metaData,
          resultId,
          generalComment,
          addedComments,
          additions,
          notes,
          addedTeeth,
          changes,
          removedTeeth,
          movedTeeth,
          cariesPro,
          bonelossPro: bonelossPro ?? undefined, // remove it from the request if the value is null
          forms:
            bonelossPro !== null
              ? {
                  boneLoss: boneLoss,
                }
              : undefined,
        }
  try {
    if (isOwner) {
      const params: ContextQuery = yield select(getContextQueryParams)
      yield call(requestSendChanges, data, params)
    }
    // Only when assigning an image to a patient do we reload the image to get latest changes
    if (
      savingData.savingChanges === SavingType.ImageMetaData &&
      isDashboard(history.location)
    ) {
      const imageId: string = yield select(getImageId)
      yield put(loadImage(imageId))
    }
    if (savingData.savingChanges !== SavingType.ImageMetaData) {
      yield put(
        savingDataActions.requestSendChangesComplete({
          success: true,
          id: resultId,
          savedWithButton: savingData.savedWithButton || false,
        })
      )
    }
    yield put(savingDataActions.setSavingResultStatus(ResultStatus.None))
    if (openedModal) {
      yield put(closeModal())
    }
  } catch (error) {
    yield put(savingDataActions.setSavingResultStatus(ResultStatus.Error))
    yield put(
      savingDataActions.requestSendChangesComplete({
        success: false,
        id: resultId,
        savedWithButton: false,
      })
    )
    yield put(
      openModal({
        openedModal: Modals.FaqModal,
        currentFaqTab: HelpModalEntry.ReportProblem,
      })
    )
    console.error(error)
    // TODO (Tim): Show user the error screen.
  }
}

function* requestSendEventSaga({
  payload: button,
}: ReturnType<typeof savingDataActions.requestSendEvent>) {
  const resultId: string = yield select(routeSelectors.getRouteImageId)
  const openDateMs: number = yield select(getOpenDateMs)
  const editorOpenMs = openDateMs ? Date.now() - openDateMs : undefined
  const imageManipulation: IImageManipulation = {
    brightness: yield select(getBrightnessSnapped),
    contrast: yield select(getContrastSnapped),
    saturation: yield select(getSaturationSnapped),
  }
  const usedFullscreen: boolean = yield select(
    imageControlsSelectors.getUsedFullscreen
  )
  const activePatientListItem: IActivePatientListItem | null = yield select(
    getActivePatientListItem
  )
  const activeFullMouthImage: string | null = yield select(
    getActiveFullMouthImage
  )

  try {
    yield call(requestSendEvent, {
      action: "close",
      resultId: activeFullMouthImage || activePatientListItem?.id || resultId,
      editorOpenMs,
      button,
      imageManipulation,
      usedFullscreen,
    })
  } catch (error) {
    console.log(error)
  }
}

function* requestSendChangesCompleteSaga({
  payload,
}: ReturnType<typeof savingDataActions.requestSendChangesComplete>) {
  if (payload.success && payload.savedWithButton) {
    notifyWebkitSave(payload.id)
  }
}

function* allowUserToSaveSaga() {
  const isReportSentToAlfaDocs: boolean = yield select(
    getIsReportSentToAlfaDocs
  )
  if (isDashboard(history.location)) {
    yield put(savingDataActions.setDataIsChanged(true))
  }

  // Reset the possibility to navigate to report via breadcrumbs after changes.
  yield put(setReportBreadcrumb(""))
  yield put(setShowDrawAnnotationButton(false))
  if (isReportSentToAlfaDocs) {
    yield put(setIsReportSentToAlfaDocs(false))
  }
}

function* setNotificationDismissedSaga({
  payload: { id, type },
}: ReturnType<typeof savingDataActions.setNotificationDismissed>) {
  const imageMeta: IMeta = yield select(getImageMeta)
  const activePatientListItem: IActivePatientListItem | null = yield select(
    getActivePatientListItem
  )
  if (
    imageMeta.warnings?.orthoCondition &&
    !imageMeta.orthoConditionDismissed &&
    type === DismissalType.OrthoConditionDismissed
  ) {
    yield put(saveImageMeta({ ...imageMeta, orthoConditionDismissed: true }))
    yield put(
      setPatientImageMeta({
        id,
        meta: { ...imageMeta, orthoConditionDismissed: true },
      })
    )
  } else if (
    imageMeta.autoRotated &&
    activePatientListItem &&
    type === DismissalType.AutoRotationDismissed
  ) {
    yield put(saveImageMeta({ ...imageMeta, autoRotationDismissed: true }))
    yield put(
      setPatientImageMeta({
        id,
        meta: { ...imageMeta, autoRotationDismissed: true },
      })
    )
  }
  yield put(
    savingDataActions.requestSendChanges({
      resultId: id,
      savingChanges: SavingType.ImageMetaData,
    })
  )
}

export default function* savingDataSaga() {
  yield takeLatest(SavingTypes.RequestSendChanges, requestSendChangesSaga)
  yield takeLatest(
    SavingTypes.RequestSendChangesComplete,
    requestSendChangesCompleteSaga
  )

  const { CreateTooth, DeleteTooth } = teethTypes
  const { ToggleCariesPro, ToggleBonelossPro } = UserTypes
  const { ToggleFlipImage } = ServerDataTypes

  yield takeEvery(
    [
      CreateTooth,
      DeleteTooth,
      AdjustmentTypes.ToggleAnnotationOnTooth,
      ToggleCariesPro,
      ToggleFlipImage,
      AdjustmentTypes.MoveAnnotationTo,
      // For cariesPRO annotations
      ServerDataTypes.AddCariesAdditions,
      ServerDataTypes.DeleteUserAdditionById,
      ServerDataTypes.ToggleAnnotation,
      ServerDataTypes.ChangeAnnotation,
      // For system tests
      ServerDataTypes.ToggleDisplayHorizontallyFlipped,
      // For boneloss
      ToggleBonelossPro,
      ServerDataTypes.SetToothBoneloss,
      ServerDataTypes.SetTeethLoss,
      ServerDataTypes.SetBonelossCategory,
      ServerDataTypes.SetBonelossIndex,
      ServerDataTypes.SetAge,
      ServerDataTypes.SetDiabetes,
      ServerDataTypes.SetSmoking,
      ServerDataTypes.SetExtendedOrDistribution,
      ServerDataTypes.SetComplexity,
    ],
    allowUserToSaveSaga
  ) // one more case is when HSM changes are accepted or rejected but this is found in hsmControlButtons.
  yield takeLatest(SavingTypes.RequestSendEvent, requestSendEventSaga)
  yield takeLatest(SavingTypes.RequestSendEvent, resetOpenDataMsSaga)
  yield takeLatest(
    SavingTypes.SetNotificationDismissed,
    setNotificationDismissedSaga
  )
}
