import { createAction } from "typesafe-actions"

import { EntitiesTypes } from "library/common/types/entitiesTypes"
import {
  Detection,
  Boneloss,
  ToothSegment,
  HistoricalResult,
  NerveCanal,
  ICroppedTeeth,
} from "library/common/types/dataStructureTypes"
import { Tooth } from "@dentalxrai/transform-landmark-to-svg"

export const saveAnnotations = createAction(EntitiesTypes.SaveDetections)<{
  apical: Detection[]
  boneloss?: Boneloss
  caries: Detection[]
  restorations: Detection[]
  detectedTeeth: Tooth[]
  calculus: Detection[]
  nervus: Detection[]
  segments: ToothSegment[]
  impacted: Detection[]
  historicalResults: HistoricalResult[]
  nerveCanal: NerveCanal[]
  croppedTeeth?: ICroppedTeeth
}>()
export const setInitialState = createAction(EntitiesTypes.SetInitialState)()
export const setImageId = createAction(EntitiesTypes.SetImageId)<string>()
export const setViewed = createAction(EntitiesTypes.SetViewed)<boolean>()
export const addDetectedTeeth = createAction(EntitiesTypes.AddDetectedTeeth)<
  Tooth[]
>()
export const deleteDetectedTooth = createAction(
  EntitiesTypes.DeleteDetectedTooth
)<Tooth>()
