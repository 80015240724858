import { createAction as action } from "typesafe-actions"

import types from "../types/teethTypes"

export const setActiveTooth = action(types.SetActiveTooth)<number | null>()
export const saveDetectedTeeth = action(types.SaveDetectedTeeth)<
  Array<{ toothName: number }>
>()
export const setInitialState = action(types.SetInitialState)()
export const createTooth = action(types.CreateTooth)<{
  id: number | null
  removeRejectedAnnotations: boolean
}>()
export const deleteTooth = action(types.DeleteTooth)<{
  id: number | null
  rejectAnnotations: boolean
}>()
